import GnuralRTC from './GnuralRTC';
import store from '../../Redux/store';
import { onRemoteStream } from '../../Redux/Actions/mediaActions';
import { toggleFullscreenRemote} from '../../Redux/Actions/userinterfaceActions';
import Logger from '../Logging';

const INFO = 'Utilities/Gnural/RemoteMediaHolder';

class RemoteMediaHolder {
	constructor() {
		this.setRemoteStream = this.setRemoteStream.bind(this);
		this.getRemoteStream = this.getRemoteStream.bind(this);
		this.onDisconnect = this.onDisconnect.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);

		GnuralRTC.Instance.bind('remotestream' , this.setRemoteStream);
		GnuralRTC.Instance.bind('peerconnectionlost', this.onDisconnect);
		GnuralRTC.onReopen((NewGnuralRTC) => {
			NewGnuralRTC.Instance.bind('remotestream' , this.setRemoteStream);
			NewGnuralRTC.Instance.bind('peerconnectionlost', this.onDisconnect);
		});

		this.isControlDown = false;
		this.remoteStream = undefined;

		document.addEventListener('keydown', this.onKeyDown);
		document.addEventListener('keyup', this.onKeyUp);
	}

	onKeyDown(event) {
		if (event.code === 'ShiftLeft') {
			this.isControlDown = true;
		}

		if (event.code === 'Backquote' && this.isControlDown) {
			store.dispatch(toggleFullscreenRemote());
		}
	}

	onKeyUp(event) {
		if (event.code === 'ControlLeft') {
			this.isControlDown = false;
		}
	}

	setRemoteStream(stream) {
		Logger.log(INFO, 'Got new Remote Stream: ', stream);
		if (this.remoteStream && this.remoteStream.stream) {
			try {
				const videoTracks = this.remoteStream.stream.getVideoTracks();
				videoTracks.forEach(track => {
					track.stop();
				});
				const audioTracks = this.remoteStream.stream.getAudioTracks();
				audioTracks.forEach(track => {
					track.stop();
				});
			} catch (e) {
				Logger.error(INFO, 'Failed to Stop Tracks in Remote Stream: ', this.remoteStream);
			}
		} 
		this.remoteStream = stream;
		window.newStream = stream;
		store.dispatch(onRemoteStream(stream));
	}

	getRemoteStream() {
		return (this.remoteStream ? this.remoteStream.stream : undefined);
	}

	onDisconnect() {
		this.remoteStream = undefined;
		store.dispatch(onRemoteStream(undefined));
	}
}

const holder = new RemoteMediaHolder();
export default holder;