import React from 'react';
import { connect } from 'react-redux';
import { setCurrentInformationPanel } from '../../../../Redux/Actions/userinterfaceActions';
import { leaveShow } from '../../../../Redux/Actions/showActions';
import { disconnectSelf } from '../../../../Redux/Actions/callActions';
import './ShowButton.css';

function mapStateToProps(store) {
	return {
		remotePeerID: store.media.remotePeerID,
		inShow: store.callin.inShow,
		CookieGuid: store.callin.CookieGuid,
		ShowName: store.callin.ShowName,
		CallState: store.call.CallState
	};
}

class ShowButton extends React.Component {
	constructor(props) {
		super(props);

		this.onSetCurrentInformationPanel = this.onSetCurrentInformationPanel.bind(this);
		this.getProperDisplay = this.getProperDisplay.bind(this);
		this.onLeaveShow = this.onLeaveShow.bind(this);
		this.onDisconnectSelf = this.onDisconnectSelf.bind(this);
	}

	onSetCurrentInformationPanel(panelName) {
		return () => {
			this.props.dispatch(setCurrentInformationPanel(panelName));
		};
	}

	onLeaveShow() {
		this.props.dispatch(leaveShow({...this.props}));
	}

	onDisconnectSelf() {
		this.props.dispatch(disconnectSelf());
	}

	getProperDisplay() {
		if (this.props.remotePeerID || this.props.CallState === 'ACTIVE') {
			return (
				<div className={'Button Error'} onClick={this.onDisconnectSelf}>
					<div className={''}>Disconnect</div>
				</div>
			);
		}
		if (this.props.inShow) {
			return (
				<div className={'Button Error'}>
					<div className={''} onClick={this.onLeaveShow}>Leave Show</div>
				</div>
			);
		}
		return (
			<div className={'Button Secondary'} onClick={this.onSetCurrentInformationPanel('JoinShow')}>
				<div className={''}>Join Show</div>
			</div>
		);
	}

	render() {
		return this.getProperDisplay(); 
	}
}

export default connect(mapStateToProps)(ShowButton);