import * as React from 'react';

const SvgComponent = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='-25 -25 332.213 332.213'
		style={{
			enableBackground: 'new 0 0 282.213 282.213',
		}}
		xmlSpace='preserve'
		onClick={props.onClick}
		className={props.className}
	>
		<path d='M225.154 89.395c-.521 0-1.356.03-1.888.093-6.811.792-12.108 6.838-12.108 13.694v42.39c0 3.413-1.777 6.514-5.19 6.514h-.111c-3.693 0-7.698-3.328-7.698-7.021V76.687c0-6.856-4.484-12.901-11.295-13.694a20.754 20.754 0 0 0-1.952-.092c-7.36-.001-13.752 6.021-13.752 13.381v69.028c0 3.558-2.395 6.442-5.952 6.442-3.61 0-6.048-2.928-6.048-6.538V57.418c0-6.857-4.855-12.902-11.667-13.694a16.754 16.754 0 0 0-1.767-.093c-7.36-.001-13.567 6.021-13.567 13.381v88.21c0 3.607-2.934 6.53-6.54 6.53-3.563 0-6.46-2.888-6.46-6.45V76.687c0-6.856-4.726-12.901-11.538-13.694a18.281 18.281 0 0 0-1.831-.092c-7.36-.001-13.631 6.021-13.631 13.381v99.604c0 3.695-2.644 6.164-5.811 6.164-1.458 0-2.756-.523-3.992-1.691l-15.78-14.989a13.098 13.098 0 0 0-9.32-3.864 13.15 13.15 0 0 0-9.343 3.864c-5.152 5.151-5.14 13.582.012 18.734l56.407 74.39a.064.064 0 0 1 .014.027l1.412 18.574c.221 2.931 2.665 4.99 5.602 4.99h.008l98.451.128c2.816-.004 5.195-1.99 5.563-4.783l2.569-19.518c.109-.838.036-1.607.493-2.318 15.017-23.391 19.715-62.014 19.715-103.841v-48.976c-.001-7.36-5.646-13.382-13.005-13.382z' stroke={props.Color} fill={props.Color} />
		<path d='M80.021 65.007a6 6 0 0 0 5.414-8.579c-1.385-2.91-2.087-5.914-2.087-8.928 0-19.243 28.65-35.5 62.563-35.5s62.563 16.257 62.563 35.5c0 3.013-.702 6.017-2.087 8.927a6 6 0 0 0 10.836 5.156c2.157-4.534 3.251-9.272 3.251-14.083 0-26.636-32.752-47.5-74.563-47.5S71.348 20.864 71.348 47.5c0 4.812 1.094 9.55 3.251 14.084a6 6 0 0 0 5.422 3.423z' stroke={props.Color} fill={props.Color}/>
	</svg>
);

export default SvgComponent;
