import React from 'react';
import style from './ToggleSwitch.module.css';


const ToggleSwitch = (props) => {
	return (
		<div className={[style.ToggleSwitchWrapper, (props.OuterWrapperClass || null)].join(' ')}>
			<div 
				className={[style.ToggleSwitch, (props.InnerWrapperClass || null), (props.IsActive ? style.Active : style.Inactive)].join(' ')}
				onClick={props.ToggleHandler}
			>
				<div className={[style.SwitchBody, style.ShadowMild].join(' ')}></div>
				<div className={[style.Slider, style.ShadowModerate].join(' ')}></div>
			</div>
			{props.Label ? <div className={[style.Label, (props.IsActive ? style.Active : style.Inactive)].join(' ')}>{props.Label}</div> : null}
		</div>
	);
};

export default ToggleSwitch;