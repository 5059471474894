export function setBrowserInformation(browserInformation) {
	return ({
		type: 'SET_BROWSER_INFORMATION',
		payload: browserInformation
	});
}

export function setConfId(ConfId) {
	return ({
		type: 'SET_CONFID',
		payload: ConfId
	});
}