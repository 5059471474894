const defaultState = {
	BrowserName: '',
	BrowserVer: '',
	OsVer: '',
	isChrome: false,
	isEdge: false,
	isFirefox: false,
	isIE: false,
	isMobileDevice: false,
	isOpera: false,
	isSafari: false,
	VideoPermission: true,
	AudioPermission: true,
	ShouldConfigureMedia: false,
	hasSetBrowserInformation: false,
	ConfId: '',
	ConfirmationState: 'PENDING',
	ConfirmationError: ''
};

function SessionInformation(state={...defaultState}, action) {
	switch(action.type) {
	case 'SET_BROWSER_INFORMATION': {
		if (!action.payload) {
			return state;
		}
		return {...state, ...action.payload, hasSetBrowserInformation: true};
	}
	case 'SET_CONFID': {
		if (!action.payload) {
			return state;
		}
		return {...state, ConfId: action.payload};
	}
	case 'ON_FINISH_SIGNUP_FULFILLED': {
		return {
			...state,
			ConfirmationState: 'FULFILLED'
		};
	}
	case 'ON_FINISH_SIGNUP_REJECTED': {
		if (!action.error && !action.payload.Error) {
			return state;
		}
		let error = action.error || action.payload.Error || '';
		if (action.payload.Error && action.payload.Error.includes('SignUp Id Not Found')) {
			error = 'The Provided Confirmation ID does Not Exist';
		}
		if (action.payload.Error && action.payload.Error.includes('Guid should contain 32 digits with 4 dashes')) {
			error = 'Invalid Confirmation ID format Provided';
		}
		return {
			...state,
			ConfirmationState: 'REJECTED',
			ConfirmationError: error
		};
	}
	case 'ON_SET_VIDEO_PERMISSION': {
		return {
			...state,
			VideoPermission: action.payload
		};
	}
	case 'ON_SET_AUDIO_PERMISSION': {
		return {
			...state,
			AudioPermission: action.payload
		};
	}
	case 'ON_SET_SHOULD_CONFIGURE_MEDIA': {
		return {
			...state,
			ShouldConfigureMedia: action.payload
		};
	}
	default:
		return state;
	}
}

export default SessionInformation;