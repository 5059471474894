import React from 'react';
import TextField from '../Main/TextField';
import style from './Configurator.module.css';

const JoinShowFieldInput = (props) => {
	return (
		<div className={style.InputWrapperInnerStyle}>
			<TextField
				WrapperStyle={style.InputStyle}
				onChange={props.onTextChange}
				value={props.textValue}
				placeholder={props.textPlaceholder}
				label={props.textLabel}
			/>
			<div className={style.ToggleWrapper}>
				<div>Is Visible</div>
				<div
					onClick={props.onToggleChange}
					className={[style.ToggleButton, props.isToggleActive ? style.Active : ''].join(' ')}
				/>
			</div>
		</div>
	);
};

export default JoinShowFieldInput;
