import * as React from 'react';

const SvgComponent = (props) => (
	<svg width={21} height={21} viewBox={'-0.5 -0.5 22 22'} xmlns='http://www.w3.org/2000/svg'
		onClick={props.onClick}
		className={props.className}
	>
		<g
			fill={props.Color}
			fillRule='evenodd'
			stroke={props.Color}
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<path d='M5 3.5h11a2 2 0 0 1 2 2v6.049a2 2 0 0 1-1.85 1.994l-.158.006-11-.042a2 2 0 0 1-1.992-2V5.5a2 2 0 0 1 2-2zM5.464 15.5H15.5M7.5 17.5h6' stroke={props.Color} fill={props.Color}/>
		</g>
	</svg>
);

export default SvgComponent;