import React from 'react';
import { connect } from 'react-redux';
import {
	dropUserId
} from '../../../../Redux/Actions/callActions';

import './OutgoingCallPanel.css';

function mapStateToProps(store) {
	return ({
		remoteUserId: store.call.UserId,
		remoteDispName: store.call.DispName,
		CallerAvatar: store.usermanagement.CallerAvatar,
		DefaultAvatar: store.usermanagement.DefaultAvatar
	});
}

class OutgoingCallPanel extends React.Component {
	constructor(props) {
		super(props);

		this.onCancelCall = this.onCancelCall.bind(this);
		this.onImageError = this.onImageError.bind(this);
	}

	onCancelCall() {
		this.props.dispatch(dropUserId(this.props.remoteUserId));
	}
	
	onImageError(event) {
		if (!event || !event.target) {
			return;
		}
		event.target.src = this.props.DefaultAvatar;
	}

	render() {
		return (
			<div className={'FlexColumnWrapper'}>
				<div className={'IndentHeaderWrapper'}>
					<div>{'Calling'}</div>
					<div className={'CallerText'}>{this.props.remoteDispName}</div>
				</div>
				<div className={'OutgoingCallInnerWrapper NoiseBackground'}>
					<img src={this.props.CallerAvatar} onError={this.onImageError} alt='Caller Avatar'/>
					<div>
						<div className={'TransparentButton RedHaze Static'} onClick={this.onCancelCall}>Cancel Call</div>
					</div>
				</div>
				<div className={'HorizontalRule'}/>
				<div className={'BodyFiller NoiseBackground'}/>
				<div className={'FooterFiller'}/>
			</div>
		);
	}
}

export default connect(mapStateToProps)(OutgoingCallPanel);