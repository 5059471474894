import React from 'react';
import TextField from '../Main/TextField';
import style from './Configurator.module.css';

const RGBColorInput = (props) => {
	return (
		<div className={style.InputWrapperOuterStyle}>
			<div className={style.InputWrapperHeaderStyle}>
				<div className={style.Header}>{props.label}</div>
				<div className={style.ColorChecker}
					style={{['backgroundColor']: 'rgb(' + props.RValue + ',' + props.GValue + ',' + props.BValue + ')'}}/>
			</div>
			<div className={style.InputWrapperInnerStyle}>
				<TextField
					WrapperStyle={style.InputStyle}
					onChange={props.onRChange}
					value={props.RValue}
					placeholder={'Red'}
					label={'R (0-255)'}
				/>
				<TextField
					WrapperStyle={style.InputStyle}
					onChange={props.onGChange}
					value={props.GValue}
					placeholder={'Green'}
					label={'G (0-255)'}
				/>
				<TextField
					WrapperStyle={style.InputStyle}
					onChange={props.onBChange}
					value={props.BValue}
					placeholder={'Blue'}
					label={'B (0-255)'}
				/>
			</div>
		</div>
	);
};

export default RGBColorInput;