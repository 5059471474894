import React from 'react';
import { connect } from 'react-redux';
import { setConfiguratorRGBField, setConfiguratorAlphaField, setConfiguratorScalarField, setConfiguratorTextField, toggleConfiguratorShowFieldVisibility, applyConfigurator, resetConfigurator, setConfiguratorShowName } from '../../Redux/Actions/configurator';

import RGBColorInput from './RGBColorInput';
import JoinShowFieldInput from './JoinShowFieldInput';

import style from './Configurator.module.css';
import TextField from '../Main/TextField';

function mapStateToProps(store) {
	return {
		...store.configurator
	};
}

class Configurator extends React.Component {
	constructor(props) {
		super(props);

		this.onSetConfiguratorRGBField = this.onSetConfiguratorRGBField.bind(this);
		this.onSetConfiguratorAlphaField = this.onSetConfiguratorAlphaField.bind(this);
		this.onSetConfiguratorScalarField = this.onSetConfiguratorScalarField.bind(this);
		this.onSetConfiguratorTextField = this.onSetConfiguratorTextField.bind(this);
		this.onToggleConfiguratorShowFieldVisibility = this.onToggleConfiguratorShowFieldVisibility.bind(this);
		this.onApplyConfigurator = this.onApplyConfigurator.bind(this);
		this.onResetConfigurator = this.onResetConfigurator.bind(this);
		this.onSetConfiguratorShowName = this.onSetConfiguratorShowName.bind(this);
		this.generateCompleteLink = this.generateCompleteLink.bind(this);
		this.onCopyCompleteLinkToClipboard = this.onCopyCompleteLinkToClipboard.bind(this);
	}

	onSetConfiguratorRGBField(fieldName) {
		return (event) => {
			if (!event | !event.target) { return; }
			this.props.dispatch(setConfiguratorRGBField(fieldName, event.target.value));
		};
	}
	
	onSetConfiguratorAlphaField(fieldName) {
		return (event) => {
			if (!event | !event.target) { return; }
			this.props.dispatch(setConfiguratorAlphaField(fieldName, event.target.value));
		};
	}
	
	onSetConfiguratorScalarField(fieldName) {
		return (event) => {
			if (!event | !event.target) { return; }
			this.props.dispatch(setConfiguratorScalarField(fieldName, event.target.value));
		};
	}
	
	onSetConfiguratorTextField(fieldName) {
		return (event) => {
			if (!event | !event.target) { return; }
			this.props.dispatch(setConfiguratorTextField(fieldName, event.target.value));
		};
	}

	onToggleConfiguratorShowFieldVisibility(fieldNumber) {
		return (event) => {
			if (!event | !event.target) { return; }
			this.props.dispatch(toggleConfiguratorShowFieldVisibility(fieldNumber));
		};
	}

	onApplyConfigurator()
	{
		this.props.dispatch(applyConfigurator());
	}

	onResetConfigurator()
	{
		this.props.dispatch(resetConfigurator());
	}

	onSetConfiguratorShowName(event)
	{
		if (!event || !event.target) { return; }
		this.props.dispatch(setConfiguratorShowName(event.target.value ?? ''));
	}
	
	generateCompleteLink()
	{
		const e = encodeURIComponent;
		return (
			'https://' + this.props.clientSite + '/' + this.props.showName + '?' +
			'lightScalar=' + e(this.props.lightScalar) + '&' +
			'veryLightScalar=' + e(this.props.veryLightScalar) + '&' +
			'darkScalar=' + e(this.props.darkScalar) + '&' +
			'primaryColor=' + e(this.props.primaryColorR + ',' + this.props.primaryColorG + ',' + this.props.primaryColorB) + '&' +
			'primaryColorText=' + e(this.props.primaryColorTextR + ',' + this.props.primaryColorTextG + ',' + this.props.primaryColorTextB) + '&' +
			'secondaryColor=' + e(this.props.secondaryColorR + ',' + this.props.secondaryColorG + ',' + this.props.secondaryColorB) + '&' +
			'secondaryColorText=' + e(this.props.secondaryColorTextR + ',' + this.props.secondaryColorTextG + ',' + this.props.secondaryColorTextB) + '&' +
			'surfaceColor=' + e(this.props.surfaceColorR + ',' + this.props.surfaceColorG + ',' + this.props.surfaceColorB) + '&' +
			'surfaceColorText=' + e(this.props.surfaceColorTextR + ',' + this.props.surfaceColorTextG + ',' + this.props.surfaceColorTextB) + '&' +
			'errorColor=' + e(this.props.errorColorR + ',' + this.props.errorColorG + ',' + this.props.errorColorB) + '&' +
			'errorColorText=' + e(this.props.errorColorTextR + ',' + this.props.errorColorTextG + ',' + this.props.errorColorTextB) + '&' +
			'affirmativeColor=' + e(this.props.affirmativeColorR + ',' + this.props.affirmativeColorG + ',' + this.props.affirmativeColorB) + '&' +
			'affirmativeColorText=' + e(this.props.affirmativeColorTextR + ',' + this.props.affirmativeColorTextG + ',' + this.props.affirmativeColorTextB) + '&' +
			'backgroundColor=' + e(this.props.backgroundColorR + ',' + this.props.backgroundColorG + ',' + this.props.backgroundColorB) + '&' +
			'borderColor=' + e(this.props.borderColorR + ',' + this.props.borderColorG + ',' + this.props.borderColorB) + '&' +
			'shadowColor=' + e(this.props.shadowColorR + ',' + this.props.shadowColorG + ',' + this.props.shadowColorB) + '&' +
			'standbyColor=' + e(this.props.standbyColorR + ',' + this.props.standbyColorG + ',' + this.props.standbyColorB) + '&' +
			'screeningColor=' + e(this.props.screeningColorR + ',' + this.props.screeningColorG + ',' + this.props.screeningColorB) + '&' +
			'offAirColor=' + e(this.props.offAirColorR + ',' + this.props.offAirColorG + ',' + this.props.offAirColorB) + '&' +
			'offAirColorText=' + e(this.props.offAirColorTextR + ',' + this.props.offAirColorTextG + ',' + this.props.offAirColorTextB) + '&' +
			'onAirColor=' + e(this.props.onAirColorR + ',' + this.props.onAirColorG + ',' + this.props.onAirColorB) + '&' +
			'onAirColorText=' + e(this.props.onAirColorTextR + ',' + this.props.onAirColorTextG + ',' + this.props.onAirColorTextB) +
			(this.props.headerLogoImage ? '&headerLogoImage=' + e(this.props.headerLogoImage) : '') +
			(this.props.outOfShowImage ? '&outOfShowImage=' + e(this.props.outOfShowImage) : '') +
			(this.props.screeningShowImage ? '&screeningShowImage=' + e(this.props.screeningShowImage) : '') +
			(this.props.studioShowImage ? '&studioShowImage=' + e(this.props.studioShowImage) : '') +
			(this.props.completedShowImage ? '&completedShowImage=' + e(this.props.completedShowImage) : '') +
			(this.props.CustomShowField1IsVisible ? '&field1Label=' + e(this.props.CustomShowField1Label) : '') +
			(this.props.CustomShowField2IsVisible ? '&field2Label=' + e(this.props.CustomShowField2Label) : '') +
			(this.props.CustomShowField3IsVisible ? '&field3Label=' + e(this.props.CustomShowField3Label) : '') +
			(this.props.CustomShowField4IsVisible ? '&field4Label=' + e(this.props.CustomShowField4Label) : '') +
			(this.props.CustomShowField5IsVisible ? '&field5Label=' + e(this.props.CustomShowField5Label) : '')
		);
	}
	
	onCopyCompleteLinkToClipboard() {
		navigator.clipboard.writeText(this.generateCompleteLink());
	}


	render() {
		return (
			<div className={style.ConfiguratorWrapper}>
				<div className={style.InputWrapperOuterStyle}>
					<div className={style.Header}>Guest Site Configurator</div>
					<TextField
						label={'Show Name'}
						onChange={this.onSetConfiguratorShowName}
						value={this.props.showName}
						placeholder={'Name of the Show to Generate a Link For'}
					/>
					<div className={style.LinkWrapperStyle}>
						<TextField
							label={'Custom URL'}
							value={this.generateCompleteLink()}
							placeholder={'Custom Show/Settings URL'}
							readOnly={true}
						/>
						<div className={'Button Primary ' + style.Button} onClick={this.onCopyCompleteLinkToClipboard}>Copy</div>
					</div>
					<div className={style.ButtonWrapperStyle}>
						<div className={'Button Primary'} onClick={this.onApplyConfigurator}>Apply</div>
						<div className={'Button Error'} onClick={this.onResetConfigurator}>Reset</div>
					</div>
				</div>
				<div className={style.InputWrapperOuterStyle}>
					<div className={style.Header}>Join Show Fields</div>
					<JoinShowFieldInput
						textLabel={'Custom Show Field 1'}
						textValue={this.props.CustomShowField1Label}
						textPlaceholder={'Custom Show Field 1'}
						onTextChange={this.onSetConfiguratorTextField('CustomShowField1Label')}
						isToggleActive={this.props.CustomShowField1IsVisible}
						onToggleChange={this.onToggleConfiguratorShowFieldVisibility(1)}
					/>
					<JoinShowFieldInput
						textLabel={'Custom Show Field 2'}
						textValue={this.props.CustomShowField2Label}
						textPlaceholder={'Custom Show Field 2'}
						onTextChange={this.onSetConfiguratorTextField('CustomShowField2Label')}
						isToggleActive={this.props.CustomShowField2IsVisible}
						onToggleChange={this.onToggleConfiguratorShowFieldVisibility(2)}
					/>
					<JoinShowFieldInput
						textLabel={'Custom Show Field 3'}
						textValue={this.props.CustomShowField3Label}
						textPlaceholder={'Custom Show Field 3'}
						onTextChange={this.onSetConfiguratorTextField('CustomShowField3Label')}
						isToggleActive={this.props.CustomShowField3IsVisible}
						onToggleChange={this.onToggleConfiguratorShowFieldVisibility(3)}
					/>
					<JoinShowFieldInput
						textLabel={'Custom Show Field 4'}
						textValue={this.props.CustomShowField4Label}
						textPlaceholder={'Custom Show Field 4'}
						onTextChange={this.onSetConfiguratorTextField('CustomShowField4Label')}
						isToggleActive={this.props.CustomShowField4IsVisible}
						onToggleChange={this.onToggleConfiguratorShowFieldVisibility(4)}
					/>
					<JoinShowFieldInput
						textLabel={'Custom Show Field 5'}
						textValue={this.props.CustomShowField5Label}
						textPlaceholder={'Custom Show Field 5'}
						onTextChange={this.onSetConfiguratorTextField('CustomShowField5Label')}
						isToggleActive={this.props.CustomShowField5IsVisible}
						onToggleChange={this.onToggleConfiguratorShowFieldVisibility(5)}
					/>
				</div>
				<div className={style.ColorWrapper}>
					<RGBColorInput
						label={'Primary Color'}
						onRChange={this.onSetConfiguratorRGBField('primaryColorR')}
						onGChange={this.onSetConfiguratorRGBField('primaryColorG')}
						onBChange={this.onSetConfiguratorRGBField('primaryColorB')}
						RValue={this.props.primaryColorR}
						GValue={this.props.primaryColorG}
						BValue={this.props.primaryColorB}
					/>
					<RGBColorInput
						label={'Primary Color Text'}
						onRChange={this.onSetConfiguratorRGBField('primaryColorTextR')}
						onGChange={this.onSetConfiguratorRGBField('primaryColorTextG')}
						onBChange={this.onSetConfiguratorRGBField('primaryColorTextB')}
						RValue={this.props.primaryColorTextR}
						GValue={this.props.primaryColorTextG}
						BValue={this.props.primaryColorTextB}
					/>
					<RGBColorInput
						label={'Secondary Color'}
						onRChange={this.onSetConfiguratorRGBField('secondaryColorR')}
						onGChange={this.onSetConfiguratorRGBField('secondaryColorG')}
						onBChange={this.onSetConfiguratorRGBField('secondaryColorB')}
						RValue={this.props.secondaryColorR}
						GValue={this.props.secondaryColorG}
						BValue={this.props.secondaryColorB}
					/>
					<RGBColorInput
						label={'Secondary Color Text'}
						onRChange={this.onSetConfiguratorRGBField('secondaryColorTextR')}
						onGChange={this.onSetConfiguratorRGBField('secondaryColorTextG')}
						onBChange={this.onSetConfiguratorRGBField('secondaryColorTextB')}
						RValue={this.props.secondaryColorTextR}
						GValue={this.props.secondaryColorTextG}
						BValue={this.props.secondaryColorTextB}
					/>
					<RGBColorInput
						label={'Surface Color'}
						onRChange={this.onSetConfiguratorRGBField('surfaceColorR')}
						onGChange={this.onSetConfiguratorRGBField('surfaceColorG')}
						onBChange={this.onSetConfiguratorRGBField('surfaceColorB')}
						RValue={this.props.surfaceColorR}
						GValue={this.props.surfaceColorG}
						BValue={this.props.surfaceColorB}
					/>
					<RGBColorInput
						label={'Surface Color Text'}
						onRChange={this.onSetConfiguratorRGBField('surfaceColorTextR')}
						onGChange={this.onSetConfiguratorRGBField('surfaceColorTextG')}
						onBChange={this.onSetConfiguratorRGBField('surfaceColorTextB')}
						RValue={this.props.surfaceColorTextR}
						GValue={this.props.surfaceColorTextG}
						BValue={this.props.surfaceColorTextB}
					/>
					<RGBColorInput
						label={'Background Color'}
						onRChange={this.onSetConfiguratorRGBField('backgroundColorR')}
						onGChange={this.onSetConfiguratorRGBField('backgroundColorG')}
						onBChange={this.onSetConfiguratorRGBField('backgroundColorB')}
						RValue={this.props.backgroundColorR}
						GValue={this.props.backgroundColorG}
						BValue={this.props.backgroundColorB}/>
					<RGBColorInput
						label={'Error Color'}
						onRChange={this.onSetConfiguratorRGBField('errorColorR')}
						onGChange={this.onSetConfiguratorRGBField('errorColorG')}
						onBChange={this.onSetConfiguratorRGBField('errorColorB')}
						RValue={this.props.errorColorR}
						GValue={this.props.errorColorG}
						BValue={this.props.errorColorB}
					/>
					<RGBColorInput
						label={'Error Color Text'}
						onRChange={this.onSetConfiguratorRGBField('errorColorTextR')}
						onGChange={this.onSetConfiguratorRGBField('errorColorTextG')}
						onBChange={this.onSetConfiguratorRGBField('errorColorTextB')}
						RValue={this.props.errorColorTextR}
						GValue={this.props.errorColorTextG}
						BValue={this.props.errorColorTextB}
					/>
					<RGBColorInput
						label={'Affirmative Color'}
						onRChange={this.onSetConfiguratorRGBField('affirmativeColorR')}
						onGChange={this.onSetConfiguratorRGBField('affirmativeColorG')}
						onBChange={this.onSetConfiguratorRGBField('affirmativeColorB')}
						RValue={this.props.affirmativeColorR}
						GValue={this.props.affirmativeColorG}
						BValue={this.props.affirmativeColorB}
					/>
					<RGBColorInput
						label={'Affirmative Color Text'}
						onRChange={this.onSetConfiguratorRGBField('affirmativeColorTextR')}
						onGChange={this.onSetConfiguratorRGBField('affirmativeColorTextG')}
						onBChange={this.onSetConfiguratorRGBField('affirmativeColorTextB')}
						RValue={this.props.affirmativeColorTextR}
						GValue={this.props.affirmativeColorTextG}
						BValue={this.props.affirmativeColorTextB}
					/>
					<RGBColorInput
						label={'Border Color'}
						onRChange={this.onSetConfiguratorRGBField('borderColorR')}
						onGChange={this.onSetConfiguratorRGBField('borderColorG')}
						onBChange={this.onSetConfiguratorRGBField('borderColorB')}
						RValue={this.props.borderColorR}
						GValue={this.props.borderColorG}
						BValue={this.props.borderColorB}
					/>
					<RGBColorInput
						label={'Shadow Color'}
						onRChange={this.onSetConfiguratorRGBField('shadowColorR')}
						onGChange={this.onSetConfiguratorRGBField('shadowColorG')}
						onBChange={this.onSetConfiguratorRGBField('shadowColorB')}
						RValue={this.props.shadowColorR}
						GValue={this.props.shadowColorG}
						BValue={this.props.shadowColorB}
					/>
					<RGBColorInput
						label={'Standby Color'}
						onRChange={this.onSetConfiguratorRGBField('standbyColorR')}
						onGChange={this.onSetConfiguratorRGBField('standbyColorG')}
						onBChange={this.onSetConfiguratorRGBField('standbyColorB')}
						RValue={this.props.standbyColorR}
						GValue={this.props.standbyColorG}
						BValue={this.props.standbyColorB}
					/>
					<RGBColorInput
						label={'Screening Color'}
						onRChange={this.onSetConfiguratorRGBField('screeningColorR')}
						onGChange={this.onSetConfiguratorRGBField('screeningColorG')}
						onBChange={this.onSetConfiguratorRGBField('screeningColorB')}
						RValue={this.props.screeningColorR}
						GValue={this.props.screeningColorG}
						BValue={this.props.screeningColorB}
					/>
					<RGBColorInput
						label={'Off-Air Color'}
						onRChange={this.onSetConfiguratorRGBField('offAirColorR')}
						onGChange={this.onSetConfiguratorRGBField('offAirColorG')}
						onBChange={this.onSetConfiguratorRGBField('offAirColorB')}
						RValue={this.props.offAirColorR}
						GValue={this.props.offAirColorG}
						BValue={this.props.offAirColorB}
					/>
					<RGBColorInput
						label={'Off-Air Color Text'}
						onRChange={this.onSetConfiguratorRGBField('offAirColorTextR')}
						onGChange={this.onSetConfiguratorRGBField('offAirColorTextG')}
						onBChange={this.onSetConfiguratorRGBField('offAirColorTextB')}
						RValue={this.props.offAirColorTextR}
						GValue={this.props.offAirColorTextG}
						BValue={this.props.offAirColorTextB}
					/>
					<RGBColorInput
						label={'On-Air Color'}
						onRChange={this.onSetConfiguratorRGBField('onAirColorR')}
						onGChange={this.onSetConfiguratorRGBField('onAirColorG')}
						onBChange={this.onSetConfiguratorRGBField('onAirColorB')}
						RValue={this.props.onAirColorR}
						GValue={this.props.onAirColorG}
						BValue={this.props.onAirColorB}
					/>
					<RGBColorInput
						label={'On-Air Color Text'}
						onRChange={this.onSetConfiguratorRGBField('onAirColorTextR')}
						onGChange={this.onSetConfiguratorRGBField('onAirColorTextG')}
						onBChange={this.onSetConfiguratorRGBField('onAirColorTextB')}
						RValue={this.props.onAirColorTextR}
						GValue={this.props.onAirColorTextG}
						BValue={this.props.onAirColorTextB}
					/>
				</div>
				<div className={style.InputWrapperOuterStyle}>
					<div className={style.Header}>{'Color Scalars'}</div>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorScalarField('lightScalar')}
						value={this.props.lightScalar}
						label={'Light Color Scalar (0.0+)'}
						palceholder={'Light Color Scalar'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorScalarField('veryLightScalar')}
						value={this.props.veryLightScalar}
						label={'Very Light Color Scalar (0.0+)'}
						palceholder={'Very Light Color Scalar'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorScalarField('darkScalar')}
						value={this.props.darkScalar}
						label={'Dark Color Scalar (0.0+)'}
						palceholder={'Dark Color Scalar'}
					/>
				</div>
				<div className={style.InputWrapperOuterStyle}>
					<div className={style.Header}>{'Alpha Levels'}</div>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorAlphaField('alphaLevel')}
						value={this.props.alphaLevel}
						label={'Header/Footer Alpha Level (0.0-1.0)'}
						palceholder={'Header/Footer Alpha Level'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorAlphaField('panelAlphaLevel')}
						value={this.props.panelAlphaLevel}
						label={'Left Panel Alpha Level (0.0-1.0)'}
						palceholder={'Left Panel Alpha Level'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorAlphaField('shadowAlpha')}
						value={this.props.shadowAlpha}
						label={'Shadow Alpha Level (0.0-1.0)'}
						palceholder={'Shadow Alpha Level'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorAlphaField('borderAlpha')}
						value={this.props.borderAlpha}
						label={'Border Alpha Level (0.0-1.0)'}
						palceholder={'Border Alpha Level'}
					/>
				</div>
				<div className={style.InputWrapperOuterStyle}>
					<div className={style.Header}>Image Resources</div>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorTextField('headerLogoImage')}
						value={this.props.headerLogoImage}
						label={'Header Logo Image (URL)'}
						palceholder={'Header Logo Image'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorTextField('outOfShowImage')}
						value={this.props.outOfShowImage}
						label={'Out of Show Background Image(URL)'}
						palceholder={'Out of Show Background Image'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorTextField('screeningShowImage')}
						value={this.props.screeningShowImage}
						label={'Inbound Queue Background Image(URL)'}
						palceholder={'Inbound Queue Background Image'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorTextField('studioShowImage')}
						value={this.props.studioShowImage}
						label={'Studio Queue Background Image(URL)'}
						palceholder={'Studio Queue Background Image'}
					/>
					<TextField
						WrapperStyle={style.InputStyle}
						onChange={this.onSetConfiguratorTextField('completedShowImage')}
						value={this.props.completedShowImage}
						label={'Completed Queue Background Image(URL)'}
						palceholder={'Completed Queue Background Image'}
					/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(Configurator);