import { useLayoutEffect, useRef, useState }from 'react';
import style from './TextField.module.css';

const GenerateDynamicClipPath = (width) => {
	const calcString = ' calc(' + (width) + 'px + ' + '1.0vmin) ';
	const clipPathString = 'polygon(0% 0%, 0.7vmin 0%, 0.7vmin 8%,' + calcString + '8%,' + calcString + '0%, 100% 0%, 100% 99%, 0% 100%)'; 

	return {
		'clipPath': clipPathString
	};
};

const TextField = (props) => {
	const labelRef = useRef(null);

	const [width, setWidth] = useState(0);

	useLayoutEffect(() => {
		setWidth(labelRef.current.offsetWidth);
	}, []);

	return (
		<div className={[style.TextField, props.WrapperStyle].join(' ')}>
			<div ref={labelRef} className={style.FieldLabel}>{props.label}</div>
			<input
				style={GenerateDynamicClipPath(width)}
				onChange={props.onChange}
				type={props.type ?? 'text'}
				value={props.value}
				onBlur={props.onBlur}
				onKeyDown={props.onKeyDown}
				placeholder={props.placeholder}
				name={props.name}
				autoComplete={props.autoComplete ?? ''}
				readOnly={props.readOnly}
			/>
		</div>
	);
};

export default TextField;