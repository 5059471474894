import React from 'react';
import { connect } from 'react-redux';
import { acceptIncomingCall, denyIncomingCall } from '../../../../Redux/Actions/callActions';

import IncomingCallPresentation from './IncomingCallPresentation';

import './IncomingCallPanel.css';

function mapStateToProps(store) {
	return {
		CallState: store.call.CallState,
		CallDispName: store.call.DispName,
		CallPeerId: store.call.PeerId,
		CallContext: store.call.Context,
		CallUserType: store.call.UserType,
		CallUserId: store.call.UserId,
		CallerAvatar: store.usermanagement.CallerAvatar,
		DefaultAvatar: store.usermanagement.DefaultAvatar
	};
}

class IncomingCallPanel extends React.Component {
	constructor(props) {
		super(props);

		this.onAcceptIncomingCall = this.onAcceptIncomingCall.bind(this);
		this.onDenyIncomingCall = this.onDenyIncomingCall.bind(this);
		this.onImageError = this.onImageError.bind(this);
	}

	onImageError(event) {
		if (!event || !event.target) {
			return;
		}
		event.target.src = this.props.DefaultAvatar;
	}

	onAcceptIncomingCall() {
		this.props.dispatch(acceptIncomingCall(
			this.props.CallPeerId,
			this.props.CallContext,
			this.props.CallUserId,
			this.props.CallDispName
		));
	}

	onDenyIncomingCall() {
		this.props.dispatch(denyIncomingCall(this.props.CallPeerId, this.props.CallUserId));
	}

	render() {
		return (
			<IncomingCallPresentation
				CallDispName={this.props.CallDispName}
				onAcceptIncomingCall={this.onAcceptIncomingCall}
				onDenyIncomingCall={this.onDenyIncomingCall}
				CallerAvatar={this.props.CallerAvatar}
				onImageError={this.onImageError}
			/>
		);
	}
}

export default connect(mapStateToProps)(IncomingCallPanel);