import React from 'react';

class ChatTabPresentation extends React.Component {
	render() {
		return (
			<div 
				onClick={this.props.onOpenChat}
				className={'ChatTabOuter ' + (this.props.isSelected ? 'active' : 'inactive') + (this.props.hasNewMessages ? ' hasNewMessages' : '')}>
				<div>{this.props.DispName}</div>
				<div onClick={this.props.onCloseChat}> x </div>
			</div>
		);
	}
}

export default ChatTabPresentation;