import React from 'react';
import { connect } from 'react-redux';
import { updateChatMessage, textToPeer } from '../../../../Redux/Actions/chatActions';

function mapStateToProps(store) {
	return {
		MessageToSend: store.chat.MessageToSend,
		SelectedChatObject: store.chat.SelectedChatObject,
		ChatObjects: store.chat.ChatObjects,
		LocalUserId: store.user.UserId,
		LocalDispName: store.user.DispName
	};
}

class ChatDisplay extends React.Component {
	constructor(props) {
		super(props);

		this.onUpdateChatMessage = this.onUpdateChatMessage.bind(this);
		this.onSendChatMessage = this.onSendChatMessage.bind(this);
		this.displayChatMessages = this.displayChatMessages.bind(this);
		this.onHandleInputEnter = this.onHandleInputEnter.bind(this);
		this.ChatDisplayTextAreaWrapperRef = React.createRef();
	}

	onUpdateChatMessage(event) {
		if (!event || !event.target) {
			return;
		}
		this.props.dispatch(updateChatMessage(event.target.value || ''));
	}

	componentDidUpdate(prevProps) {
		if (!this.props || !this.ChatDisplayTextAreaWrapperRef.current || !this.props.SelectedChatObject || !this.props.ChatObjects
			|| !this.props.ChatObjects[this.props.SelectedChatObject] || !prevProps || 
			!prevProps.SelectedChatObject || !prevProps.ChatObjects || !prevProps.ChatObjects[prevProps.SelectedChatObject] ||
			this.props.ChatObjects[this.props.SelectedChatObject] === prevProps.ChatObjects[prevProps.SelectedChatObject]) {
			return;
		}
		this.ChatDisplayTextAreaWrapperRef.current.scrollTop = this.ChatDisplayTextAreaWrapperRef.current.scrollHeight;
	}

	onSendChatMessage() {
		if(!this.props.LocalUserId || !this.props.SelectedChatObject || !this.props.MessageToSend) {
			return;
		}
		this.props.dispatch(textToPeer(this.props.LocalUserId, this.props.LocalDispName, this.props.SelectedChatObject, this.props.MessageToSend));
	}

	displayChatMessages() {
		if (!this.props.SelectedChatObject || !this.props.ChatObjects[this.props.SelectedChatObject]) {
			return [];
		}
		return this.props.ChatObjects[this.props.SelectedChatObject].Messages.map(message => {
			return (
				<div className={'MessageWrapper ' + (message.SenderUserId === this.props.LocalUserId ? 'Local' : 'Remote')} key={message.LocalCount}>
					<div>{message.Message}</div>
				</div>
			);
		});
	}

	onHandleInputEnter(event) {
		if (event.key === 'Enter') {
			this.onSendChatMessage();
		}
	}

	render() {
		return (
			<div className={'ChatDisplayWrapperOuter'}>
				<div className={'ChatDisplayTextAreaWrapper'} ref={this.ChatDisplayTextAreaWrapperRef}>
					{this.displayChatMessages()} 
				</div>
				<div className={'ChatDisplaySendBoxWrapper'}>
					<input type={'text'} onKeyPress={this.onHandleInputEnter} onChange={this.onUpdateChatMessage} value={this.props.MessageToSend} placeholder={'Enter a Message to Send...'}/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(ChatDisplay);


