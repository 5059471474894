import React from 'react';
import { connect } from 'react-redux';
import { toggleMinimizeChatWindow } from '../../../Redux/Actions/chatActions';
import './ToggleChatWindowButton.css';

function mapStateToProps(store) {
	return {
		isChatWindowMinimized: store.chat.isChatWindowMinimized,
		ChatObjects: store.chat.ChatObjects,
		hasNewMessage: Object.values(store.chat.ChatObjects).map(value => {
			return value.hasNewMessages || false;
		}).reduce((prev, curr) => {
			return prev || curr || false;
		})
	};
}

class ToggleChatWindowButton extends React.Component {
	constructor(props) {
		super(props);

		this.onToggleMinimizeChatWindow = this.onToggleMinimizeChatWindow.bind(this);
	}

	onToggleMinimizeChatWindow() {
		this.props.dispatch(toggleMinimizeChatWindow());
	}

	render() {
		return (
			<div className={'ToggleChatWindowButtonOuterWrapper BrushedMetalGradient'}>
				<div
					onClick={this.onToggleMinimizeChatWindow}
					className={'ToggleChatWindowButtonInnerWrapper ' + 
					(this.props.hasNewMessage ? 'YellowHaze ' : 'BlueHaze ') + 
					(this.props.isChatWindowMinimized && !this.props.hasNewMessage ? '' : 'active')}>
					<div className={'ToggleChatWindowButtonText'}>Toggle Chat</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(ToggleChatWindowButton);