/* eslint-disable */
import XSockets from '../XSockets.latest';
import ws from './websockets';

String.prototype.capitalize = function () {
	return this.replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
		return p1 + p2.toUpperCase();
	});
};
function trace(text) {
	// This function is used for logging.
	if (text[text.length - 1] == '\n') {
		text = text.substring(0, text.length - 1);
	}
	console.log((performance.now() / 1000).toFixed(3) + ": " + text);
}


window.requestAnimFrame = (function () {
	return window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			function (callback) {
				window.setTimeout(callback, 1000 / 60);
			};
})();

XSockets.PeerContext = function (guid, context, userId, dispName) {
	this.PeerId = guid;
	this.Context = context;
	this.UserId = userId;
	this.DispName = dispName;
};

window.AudioContext = window.AudioContext || window.webkitAudioContext;
window.URL = window.URL || window.webkitURL;

XSockets.AudioAnalyser = (function () {
	function AudioAnalyser(stream, interval, cb) {
		var self = this;
		var buflen = 2048;
		var buf = new Uint8Array(buflen);
		function autoCorrelate(buf, sampleRate) {
			var minSamples = 4;
			var maxSamples = 1000;
			var size = 1000;
			var bestOffset = -1;
			var bestCorrelation = 0;
			var rms = 0;
			var currentPitch = 0;
			if (buf.length < (size + maxSamples - minSamples))
				return;  // Not enough data
			for (var i = 0; i < size; i++) {
				var val = (buf[i] - 128) / 128;
				rms += val * val;
			}
			rms = Math.sqrt(rms / size);
			for (var offset = minSamples; offset <= maxSamples; offset++) {
				var correlation = 0;
				for (var i = 0; i < size; i++) {
					correlation += Math.abs(((buf[i] - 128) / 128) - ((buf[i + offset] - 128) / 128));
				}
				correlation = 1 - (correlation / size);
				if (correlation > bestCorrelation) {
					bestCorrelation = correlation;
					bestOffset = offset;
				}
			}
			if ((rms > 0.01) && (bestCorrelation > 0.01)) {
				currentPitch = sampleRate / bestOffset;
				var result = {
					Confidence: bestCorrelation,
					CurrentPitch: currentPitch,
					Fequency: sampleRate / bestOffset,
					RMS: rms,
					TimeStamp: new Date()
				};
				if (self.onResult)
					self.onResult(result);
				self.analyzerResult.unshift(result);
			}
		}
		function pitcher() {
			self.analyser.getByteTimeDomainData(buf);
			autoCorrelate(buf, self.audioContext.sampleRate);
		}
		this.analyzerResult = [];
		this.isSpeaking = false;
		this.onResult = undefined;
		this.onAnalysis = undefined;
		this.audioContext = new AudioContext();
		var mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
		this.analyser = this.audioContext.createAnalyser();

		mediaStreamSource.connect(this.analyser);
		this.analyser.smoothingTimeConstant = 0.8;
		this.analyser.fftSize = 2048;

		window.setInterval(function () {
			pitcher();
		}, (interval || 1000) / 10);

		setInterval(function () {
			if (self.analyzerResult.length > 5) {
				// How old is the latest confident audio analyze?
				var now = new Date();
				var result = self.analyzerResult[0];
				var lastKnown = new Date(self.analyzerResult[0].TimeStamp.getTime());
				if ((now - lastKnown) > 1000) {
					if (self.isSpeaking) {
						result.IsSpeaking = false;
						if (self.onAnalysis) self.onAnalysis(result);
						self.analyzerResult = [];
					}
					self.isSpeaking = false;
				} else {
					if (!self.isSpeaking) {
						result.IsSpeaking = true;
						if (self.onAnalysis) self.onAnalysis(result);
					}
					self.isSpeaking = true;
				}
			}
		}, 250);
		if (cb) cb();
	}
	return AudioAnalyser;

})();

XSockets.WebRTC = function (ws, settings) {

//	alert("WebRtc Ct#0");

	var isAudioMuted = false;
	var self = this;
	var localStreams = [];
	var subscriptions = new XSockets.Subscriptions(); //ws.websocket.getSubscriptions();
	var videosender;
	var audiosender;
	//
	this.PeerConnections = {};
	this.DataChannels = undefined;
	var defaults = {
		iceServers: [
		{"urls": "stun:stun.l.google.com:19302"}
		,{"urls": "turn:www.livetoair.net:3444"}
		],
		sdpConstraints: {
			optional: [],
			mandatory: {
				OfferToReceiveAudio: true,
				OfferToReceiveVideo: true
			 }
		},
		streamConstraints: {
			mandatory: {},
			optional: []
		},
		sdpExpressions: []
	};

	var options = XSockets.Utils.extend(defaults, settings);

	this.bind = function (event, fn, opts, callback) {
		subscriptions.add(event, fn);
		if (callback && typeof (callback) === "function") {
			callback();
		}
		return this;
	};
	this.unbind = function (event, callback) {
		subscriptions.remove(event);
		if (callback && typeof (callback) === "function") {
			callback();
		}
		return this;
	};
	this.dispatch = function (eventName, message, obj) {
		var _eventName = "on" + eventName;
		
		if (this.hasOwnProperty(_eventName)) {
			self[_eventName](message);
		}
		if (subscriptions.get(eventName) === null) {
			return;
		}
		if (typeof message === "string") {
			message = JSON.parse(message);
		}
		subscriptions.fire(eventName, message, function () { });
	};

	this.IsAudioMuted = function() {
		return isAudioMuted;
	}

	this.muteAudio = function (mute, cb) {
		/// <summary>Toggle mute on all local streams</summary>
		/// <param name="cb" type="Object">function to be invoked when toggled</param>
		localStreams.forEach(function (a, b) {
			var audioTracks = a.getAudioTracks();

			if (audioTracks.length === 0) {
				return;
			}
			if (!mute) {
				for (let i = 0; i < audioTracks.length; i++) {
					audioTracks[i].enabled = true;
				}
			} else {
				for (let i = 0; i < audioTracks.length; i++) {
					audioTracks[i].enabled = false;
				}
			}
		});
		isAudioMuted = mute;
		if (cb) cb(mute);
	};

	this.hasStream = function () {
		/// <summary>Determin of there is media streams attach to the local peer</summary>
		return localStreams.length > 0;
	};


	this.leaveContext = function () {
		/// <summary>Leave the current context (hang up on all )</summary>
		ws.websocket.publish("leaveContext", {

		});
		return this;
	};
	this.changeContext = function (contextGuid) {
		/// <summary>Change context on broker</summary>
		/// <param name="contextGuid" type="Object">Unique identifer of the context to 'join'</param>
		ws.websocket.publish("changecontext", {
			context: contextGuid
		});
		return this;
	};

	this.getLocalStreams = function () {
		/// <summary>Get local streams</summary>
		return localStreams;
	};
	this.removeStream = function (id, fn) {
		/// <summary>Remove the specified local stream</summary>
		/// <param name="id" type="Object">Id of the media stream</param>
		/// <param name="fn" type="Object">callback function invoked when remote peers notified and stream removed.</param>
		localStreams.forEach(function (stream, index) {
			if (stream.id === id) {
				localStreams[index].getTracks().forEach(function(track) {
						for (var peer in self.PeerConnections) {
							self.PeerConnections.removeTrack(track);
						}
						track.stop();
						console.log("Stopping Track");
					});
				localStreams.splice(index, 1);
				/*for (var peer in self.PeerConnections) {
					self.PeerConnections[peer].Connection.removeStream(stream);
					//createOffer({PeerId: peer});
 //                   ws.websocket.publish("removestream", {recipient: peer, streamId: id});
				}*/
			}
		});
		if (fn) fn(id);
	};

	this.RemoveLocalStreams = function (fn, stopTracks = true) {
		/// <summary>Remove the specified local stream</summary>
		/// <param name="fn" type="Object">callback function invoked when remote peers notified and stream removed.</param>
		localStreams.forEach(function (stream, index)
		{
			var tracks = stream.getTracks();
			for (var peer in self.PeerConnections)
			{
				if (videosender)
					self.PeerConnections[peer].Connection.removeTrack(videosender);
				if (audiosender)
					self.PeerConnections[peer].Connection.removeTrack(audiosender);
				//  delete stream;
				videosender = null;
				audiosender = null;
				/*
				if (window.BrowserName != 'Firefox')
					self.PeerConnections[peer].Connection.removeStream(stream);
				else {
					if (videosender)
						self.PeerConnections[peer].Connection.removeTrack(videosender);
					if (audiosender)
						self.PeerConnections[peer].Connection.removeTrack(audiosender);
					//  delete stream;
					videosender = null;
					audiosender = null;
				}*/
 //               ws.websocket.publish("removestream", { recipient: peer, streamId: stream.id });
			}

			tracks = localStreams[index].getTracks();
			if (stopTracks) {
				tracks.forEach(function (track) {
						track.stop();
						console.log("Stopping Track");
				});
			}
		});
		localStreams.length = 0;
		if (fn) fn();
	};

	this.userMediaConstraints = {
		qvga: function (audio) {
			return {
				video: {
					mandatory: {
						maxWidth: 320,
						maxHeight: 180
					}
				},
				audio: typeof (audio) !== "boolean" ? false : audio
			};
		},
		vga: function (audio) {
			return {
				video: {
					mandatory: {
						maxWidth: 640,
						maxHeight: 360
					}
				},
				audio: typeof (audio) !== "boolean" ? false : audio
			};
		},
		hd: function (audio) {

			return {
				video: {
					mandatory: {
						minWidth: 1280,
						minHeight: 720,
						minAspectRatio: 1.77
					},
					optional: []
				},
				audio: {
					optional: 
					[
					  {googAutoGainControl: false}, 
					  {googAutoGainControl2: false}, 
					  // {googEchoCancellation: false},
					  // {googEchoCancellation2: false},
					  // {googNoiseSuppression: false},
					  // {googNoiseSuppression2: false},
					  // {googHighpassFilter: false},
					  // {googTypingNoiseDetection: false},
					  // {googAudioMirroring: false}
					]				
				}
		   };
		},
		create: function (w, h, audio) {
			return {
				video: {
					mandatory: {
						minWidth: w,
						minHeight: h
					}
				},
				audio: typeof (audio) !== "boolean" ? false : audio
			};
		},
		screenSharing: function (audio) {
			return {
				video: {
					mandatory: {
						chromeMediaSource: "screen",
//						maxWidth: 1920,
//						maxHeight: 1080,
						minAspectRatio: 1.77
					}				
				},
				audio: typeof (audio) !== "boolean" ? false : audio
			};
		}
	};

	this.getRemotePeers = function () {
		/// <summary>Returns a list of remotePeers (list of id's)</summary>
		var ids = [];
		for (var peer in self.PeerConnections)
			ids.push({ PeerId: peer, UserId: self.PeerConnections[peer].UserId, DispName: self.PeerConnections[peer].DispName  });
		return ids;

	};

	this.refreshStreams = function (id, fn) {
		/// <summary>Reattach streams and renegotiate</summary>
		/// <param name="id" type="Object">PeerConnection id</param>
		/// <param name="fn" type="Object">callback that will be invoked when completed.</param>
		localStreams.forEach(function (stream, index) {
			self.PeerConnections[id].Connection.removeStream(localStreams[index]);
		});
		createOffer({
			PeerId: id
		});

		if (fn) fn(id);
	};

	this.addLocalStream = function (stream, cb) {
//	alert("addLocalStream: ");
		var index = localStreams.push(stream);
		// Check it there is PeerConnections 
		
		for (var key in self.PeerConnections) {
			var conn = self.PeerConnections[key];
			if (conn) {
				//if (window.BrowserName == 'Firefox') {
				//    videosender = conn.Connection.addTrack(stream.getVideoTracks()[0], stream);
				//    audiosender = conn.Connection.addTrack(stream.getAudioTracks()[0], stream);
				//} else {
				//    conn.Connection./*addStream(stream, options.streamConstraints);
				//} // done inside createOffer()
				createOffer({ PeerId: key });

			}
		}
//   e    ws.websocket.trigger("AddStream", {streamId: stream.id, description: "" });
		
		self.dispatch(XSockets.WebRTC.Events.onLocalStream, stream);
		if (cb) cb(stream, index);
		return this;
	};

	this.removePeerConnection = function (id, fn) {
		var found = false;
		if (self.PeerConnections[id] !== undefined) {
			try {
				self.PeerConnections[id].Connection.close();
				self.dispatch(XSockets.WebRTC.Events.onPeerConnectionLost, {
					PeerId: id,
					UserId: self.PeerConnections[id].UserId
				});
			} catch (err) {

			}
			found = true;
		};
		delete self.PeerConnections[id];
		if (fn) fn();
		return found;
	};

	this.CreatePeerConnection = function (peer, fn) {
		var c = new GPeerConnection(options, peer.PeerId, peer.Context, peer.UserId, peer.DispName);
		self.PeerConnections[peer.PeerId] = c;
		if (fn) fn();
		return c;
	}

	/*
	this.getUserMedia = function (constraints, success, error) {

		/// <summary>get a media stream</summary>
		/// <param name="userMediaSettings" type="Object">connstraints. i.e .usersdpConstraints.hd()</param>
		/// <param name="success" type="Object">callback function invoked when media stream captured</param>
		/// <param name="error" type="Object">callback function invoked on faild to get the media stream </param>
		
	//	alert("LocalStreams called#1");
		
		window.getUserMedia(constraints, function (stream) {
			if (success && typeof (success) === "function") success(self.CurrentContext);
			localStreams.push(stream);
	//		alert("LocalStreams called");
 //           ws.websocket.trigger("AddStream", {streamId: stream.id, description: ""});
			self.dispatch(XSockets.WebRTC.Events.onLocalStream, stream);
		}, function (err) {
			if (error && typeof (error) === "function") error(err);
		});
		return this;
	};
	*/

	// DataChannels
	this.addDataChannel = function (dc) {
		/// <summary>Add a XSockets.WebRTC.DataChannel. Channel will be offered to remote peers</summary>
		/// <param name="dc" type="Object">XSockets.WebRTC.DataChannel to add</param>
		/// <param name="success" type="Object">callback function invoked when added.</param>
		this.DataChannels = this.DataChannels || {};
		if (!this.DataChannels.hasOwnProperty(dc.name)) {
			this.DataChannels[dc.name] = dc;
		} else {
			throw "A RTCDataChannel named '" + dc.Name + "' already exists and cannot be created.";
		}



	};
	
	this.removeDataChannel = function (name, cb) {
		/// <summary>Remove a Sockets.WebRTC.DataChannel </summary>
		/// <param name="name" type="Object">name of the XSockets.WebRTC.DataChannel to remove from offers</param>
		/// <param name="success" type="Object">callback function invoked when removed</param>

		if (this.DataChannels.hasOwnProperty(name)) {
			delete this.DataChannels[name];
			// remove delegates from peers..
			for (var pc in this.PeerConnections) {
				this.PeerConnections[pc].RTCDataChannels[name].close();
				delete this.PeerConnections[pc].RTCDataChannels[name];
			}
		} else {
			throw "A RTCDataChannel named '" + name + "' does not exists.";
		}
	};

	// Private methods



	var GPeerConnection = function (configuration, peerId, Context, userId, dispName) {
		var that = this;
		this.PeerId = peerId;
		this.UserId = userId;
		this.DispName = dispName;
		const pcConstraints = configuration.sdpConstraints;
		this.RTCDataChannels = {};
		if (typeof (self.DataChannels) === "object" && configuration.sdpConstraints.optional.filter(function(option) {
			 return option.RtpDataChannels;
		}).length === 0) {
			configuration.sdpConstraints.optional.push({ RtpDataChannels: true });
		}
		const iceConfig = {
			  'iceServers': [
				 {'urls': 'stun:stun.l.google.com:19302'},
				 {'urls': 'stun:www.livetoair.net:3478'},
				 { 'urls': 'stun:54.188.158.43:3478' }
			 //     ,{
				//  'urls': 'turn:54.188.158.43:3444?transport=udp',
				//  'credential': Context,
				//  'username': Context
				//},
				//{
				//  'urls': 'turn:www.livetoair.net:3444?transport=udp',
				//  'credential': Context,
				//  'username': Context
				//}
			  ]
			}
		if (window.RelayServers != undefined) {
			if (window.RelayServers.length == 0) {
				iceConfig.iceServers.push({
					'urls': 'turn:54.188.158.43:3444?transport=udp',
					'credential': Context,
					'username': Context
				});
			} else {
				for( var relay in window.RelayServers)
					iceConfig.iceServers.push({
						'urls': window.RelayServers[relay],
					'credential': Context,
					'username': Context
				});
			}

		} else {
			iceConfig.iceServers.push({
				'urls': 'turn:54.188.158.43:3444?transport=udp',
				'credential': Context,
				'username': Context
			});
			iceConfig.iceServers.push({
				'urls': 'turn:www.livetoair.net:3444?transport=udp',
				'credential': Context,
				'username': Context
			});
		}

		this.newConnection = function() {
			console.log(iceConfig);
			console.log(pcConstraints);
			this.Connection = new RTCPeerConnection(iceConfig, pcConstraints);
			//        this.Connection = new RTCPeerConnection({ iceServers: configuration.iceServers }, configuration.sdpConstraints);
			this.Connection.oniceconnectionstatechange = function(data) {
				console.log("Connection.OnIceConnectionStateChange: ", data.currentTarget.iceConnectionState);
			};
			this.Connection.onerror = function(data) {
				console.log("Connection.OnError", data);
			};
			this.Connection.onnegotiationneeded = function(data) {
				console.log("Connection.OnNegotiationNeeded", data);
			};
			this.Connection.onremovestream = function(data) {
				console.log("Connection.OnRemoveStream", data);
			};
			this.Connection.onsignalingstatechange = function(data) {
				console.log("Connection.OnSignalingStateChange: " + data.currentTarget.signalingState);
			};
			// If there is dataChannels attach, offer em
			for (var dc in self.DataChannels) {
				var dataChannel = self.DataChannels[dc];
				this.RTCDataChannels[dataChannel.name] = this.Connection.createDataChannel(dataChannel.name, { reliable: false });
				this.RTCDataChannels[dataChannel.name].onmessage = function(data) {
					var obj = JSON.parse(data.data).JSON;
					dataChannel.subscriptions.fire(obj.event, { peerId: that.PeerId, message: JSON.parse(obj.data) }, function() {});
				};
				this.RTCDataChannels[dataChannel.name].onopen = function(event) {
					//console.log("dataChannel open ", dataChannel.name, data);
					if (dataChannel.onopen) dataChannel.onopen(that.PeerId, event);
				};
				this.RTCDataChannels[dataChannel.name].closed = function(event) {
					if (dataChannel.onclose) dataChannel.onclose(that.PeerId, event);
				};
				dataChannel.onpublish = function(topic, data) {
					var message = new XSockets.Message(topic, data);
					for (var p in self.PeerConnections) {
						self.PeerConnections[p].RTCDataChannels[dataChannel.name].send(JSON.stringify(message));
					}
				};
				dataChannel.onpublishTo = function(peerId, topic, data) {
					var message = new XSockets.Message(topic, data);
					if (self.PeerConnections[peerId])
						self.PeerConnections[peerId].RTCDataChannels[dataChannel.name].send(JSON.stringify(message));
				};
			}
			this.Connection.onaddstream = function(event) {
				self.dispatch(XSockets.WebRTC.Events.onRemoteStream, {
					PeerId: that.PeerId,
					UserId: that.UserId,
					DispName: that.DispName,
					stream: event.stream
				});
			};
			this.Connection.onicecandidate = function(event) {
				if (event.candidate) {
					var candidate = {
						type: 'candidate',
						label: event.candidate.sdpMLineIndex,
						id: event.candidate.sdpMid,
						candidate: event.candidate.candidate
					};
					ws.websocket.publish("contextsignal", {
						sender: self.CurrentContext.PeerId,
						recipient: that.PeerId,
						message: JSON.stringify(candidate)
					});
				}
			};
			self.dispatch(XSockets.WebRTC.Events.onPeerConnectionCreated, { PeerId: that.PeerId, UserId: that.UserId });
		};

 //       this.newConnection();

	};

	var setBandwidth = function (sdp) {
		const audioBandwidth = 50;
		const videoBandwidth = 2000;
		sdp = sdp.replace(/a=mid:audio\r\n/g, 'a=mid:audio\r\nb=AS:' + audioBandwidth + '\r\n'); // RS : RR
	    //sdp = sdp.replace(/a=fmtp:111.*\r\n/g, 'a=fmtp:111 minptime=10;sprop-stereo=1;stereo=1;useinbandfec=1;maxaveragebitrate=510000;\r\n');	
//        sdp = sdp.replace(/a=mid:video\r\n/g, 'a=mid:video\r\nb=AS:' + videoBandwidth + '\r\n');
//		sdp = maybePreferCodec(sdp, 'video', 'send', 'VP9');
		if (sdp.includes('a=rtpmap:125 H264/90000') && window.UseSoftwareEncoding)
		{
			let sdpLines = sdp.split('\r\n');
			let mLineIndex = findLine(sdpLines, 'm=', 'video');
			if (mLineIndex !== null)
			{
				sdpLines[mLineIndex] = setDefaultCodec(sdpLines[mLineIndex], '125');
				sdp = sdpLines.join('\r\n');
			}
		} else {
			sdp = maybePreferCodec(sdp, 'video', 'receive', 'H264');
			sdp = maybePreferCodec(sdp, 'video', 'send', 'H264');
		}

		if (sdp.includes("a=fmtp:111"))
		{
			let sdpLines = sdp.split('\r\n');
			let aLineIndex = findLine(sdpLines, 'a=', 'fmtp:111');
			if (aLineIndex !== null)
			{
				sdpLines[aLineIndex] = 'a=fmtp:111 minptime=10;sprop-stereo=1;stereo=1;useinbandfec=1';
				sdp = sdpLines.join('\r\n');
			}
		}

		return sdp;
	}

	this.CreateOffer = function(peer) {
		createOffer(peer);
	}
	
	var createOffer = function (peer) {
		if (!peer) return;

		var gconn = self.PeerConnections[peer.PeerId];

		if (gconn == null) {
			gconn = new GPeerConnection(options, peer.PeerId, peer.Context, peer.UserId, peer.DispName);
			self.PeerConnections[peer.PeerId] = gconn;
		} else {
			try { gconn.Connection.close();} catch (e) {}
		}

		gconn.newConnection();

		//if (gconn == null) {
		//    console.log("Create Offer: New Connection");
		//    gconn = self.CreatePeerConnection(peer, null);
		//} else {
		//    console.log("Create Offer: Old Connection");
		//}

//        self.PeerConnections[peer.PeerId] = new GPeerConnection(options, peer.PeerId, peer.Context, peer.UserId, peer.DispName);
		//		var conn = self.PeerConnections[peer.PeerId].Connection;

		var conn = gconn.Connection;
		localStreams.forEach(function (stream, indx) {
			const videoTrack = stream.getVideoTracks()[0];
			const audioTrack = stream.getAudioTracks()[0];
			if (videoTrack) {
				videosender = conn.addTrack(videoTrack, stream);
			}
			if (audioTrack) {
				audiosender = conn.addTrack(audioTrack, stream);
			}
		});

		var offerOptions = {
			offerToReceiveAudio: 1,
			offerToReceiveVideo: 1,
			rtcpMuxPolicy:  "require",
			iceRestart: true
		};

		conn.createOffer(function (localDescription) {
			options.sdpExpressions.forEach(function (expr, b){
				localDescription.sdp = expr(localDescription.sdp);
			}, function (failue) {
				console.log("Failed To Apply Local Descr: "+failue);
			}, offerOptions);
				
			localDescription.sdp = setBandwidth(localDescription.sdp);
			var mess = JSON.stringify(localDescription);
			conn.setLocalDescription(localDescription);
			ws.websocket.publish("contextsignal", {
				Sender: self.CurrentContext.PeerId,
				SenderId: self.CurrentContext.UserId,
				Recipient: peer.PeerId,
				Message: mess
			});
		}, 
		function (failue) 
		{
			console.log("Failed To Create Offer: "+failue);
		}
		, options.sdpConstraints);
	};

	self.bind("connect", function (peer) {
		createOffer(peer);
		self.dispatch(XSockets.WebRTC.Events.onPeerConnectionStarted, peer);
	});
	self.bind("candidate", function (event) {
		var candidate = JSON.parse(event.Message);
		if (!self.PeerConnections[event.Sender]) return;
		self.PeerConnections[event.Sender].Connection.addIceCandidate(new RTCIceCandidate({
			sdpMLineIndex: candidate.label,
			candidate: candidate.candidate
		}));
	});
	self.bind("answer", function (event) {
		self.dispatch(XSockets.WebRTC.Events.onAnswer, {
			PeerId: event.Sender
		});

		var conn = self.PeerConnections[event.Sender].Connection;
		console.log("Got Answer, Conn State: " + conn.signalingState);
		if (conn.signalingState == 'have-local-offer')
			self.PeerConnections[event.Sender].Connection.setRemoteDescription(new RTCSessionDescription(JSON.parse(event.Message)));
	});
	self.bind("offer", async function (event) {
		self.dispatch(XSockets.WebRTC.Events.onOffer, {
			PeerId: event.Sender
		});

		var gconn = self.PeerConnections[event.Sender];
		if (gconn == null) {
			console.log("Got Offer: New Connection");
			gconn = new GPeerConnection(options, event.Sender, self.CurrentContext, event.SenderId, '');
			self.PeerConnections[event.Sender] = gconn;
		} else {
			console.log("Got Offer: Old Connection");
			if (gconn && gconn.Connection) {
				gconn.Connection.close();
			}
		}
		gconn.newConnection();
//        var gconn = new GPeerConnection(options, event.Sender, self.CurrentContext, event.SenderId, '');
//        self.PeerConnections[event.Sender] = gconn;


		self.dispatch(XSockets.WebRTC.Events.onPeerConnectionStarted, { PeerId: event.Sender, UserId: gconn.UserId, DispName: gconn.DispName });
		await gconn.Connection.setRemoteDescription(new RTCSessionDescription(JSON.parse(event.Message)));
		var conn = gconn.Connection;
		localStreams.forEach(function (a, b) {
			const videoTrack = a.getVideoTracks()[0];
			const audioTrack = a.getAudioTracks()[0];
			if (videoTrack) {
				videosender = conn.addTrack(videoTrack, a);
			}
			if (audioTrack) {
				audiosender = conn.addTrack(audioTrack, a);
			}
		});

		gconn.Connection.createAnswer(function (description) {
			description.sdp = setBandwidth(description.sdp);
			gconn.Connection.setLocalDescription(description);
			options.sdpExpressions.forEach(	function (expr, b) {
				description.sdp = expr(description.sdp);
				}, 
				function (failure) {
					// dispatch the error
				//	console.log("Create Answer0 -> "+failure);
				}
			);
		
			var answer = 
			{
				Sender: self.CurrentContext.PeerId,
				Recipient: event.Sender,
				Message: JSON.stringify(description)
			};
			ws.websocket.publish("contextsignal", answer);
		}
		,function(err)
		{
			console.log("Create Answer -> "+err);
		}
		, options.sdpConstraints); //create Answer
	}); // On Offer

	ws.websocket.subscribe("contextcreated", function (context) {
		self.CurrentContext = new XSockets.PeerContext(context.PeerId, context.Context, context.UserId, context.DispName);
		self.dispatch(XSockets.WebRTC.Events.onContextCreated, context);
	}, function () {
		ws.websocket.publish('GetContext');
	});

	ws.websocket.subscribe("contextsignal", function (signal) {
		var msg = JSON.parse(signal.Message);
		self.dispatch(msg.type, signal);
	});

	ws.websocket.subscribe("contextchanged", function (change) {
		self.dispatch(XSockets.WebRTC.Events.onContextChange, change);
	});

	ws.websocket.subscribe("contextconnect", function (peers) {
		console.log("ContextConnect Signal received");
		peers.forEach(function (peer) {
			console.log("ContextConnect Signal for peer: "+peer);
			self.dispatch("connect", peer);
			//self.dispatch(XSockets.WebRTC.Events.onPeerConnectionStarted, peer);
		});
	});

	ws.websocket.subscribe("peerconnectiondisconnect", function (peer) {
		if (self.PeerConnections[peer.Sender] !== undefined) {
			self.PeerConnections[peer.Sender].Connection.close();
			self.dispatch(XSockets.WebRTC.Events.onPeerConnectionLost, {
				PeerId: peer.Sender,
				UserId: self.PeerConnections[peer.Sender].UserId
			});
			delete self.PeerConnections[peer.Sender];
		}
	});

	//??? Fake!
	ws.websocket.subscribe("streamadded", function (event) {
		self.dispatch(XSockets.WebRTC.Events.onLocalStreamCreated, event); // was onLocalSream??
	});

	ws.websocket.subscribe("streamremoved", function (event) {
		self.dispatch(XSockets.WebRTC.Events.onRemoteStreamLost, {
			PeerId: event.Sender,
			StreamId: event.StreamId
		});
	});
	
	ws.websocket.subscribe("peerconnectionlost", function (peer) {
		self.dispatch(XSockets.WebRTC.Events.onPeerConnectionLost, {
			PeerId: peer.PeerId
		});
		if (self.PeerConnections[peer.PeerId] !== undefined) {
			self.PeerConnections[peer.PeerId].Connection.close();
			delete self.PeerConnections[peer.PeerId];
		};
	});

}; // XSockets.WebRTC


XSockets.WebRTC.DataChannel = (function () {
	function DataChannel(name) {
		var self = this;
		this.subscriptions = new XSockets.Subscriptions();
		this.name = name;
		this.subscribe = function (topic, cb) {
			self.subscriptions.add(topic, cb);
		};
		this.publish = function (topic, data, cb) {
			if (!self.onpublish) return;
			self.onpublish(topic, data);
			if (cb) cb(data);
		};
		this.publishTo = function(peerId,topic, data, cb) {

		};
		this.unsubscribe = function (topic, cb) {
			self.subscriptions.remove(topic);
			if (cb) cb();
		};
		this.onbinary = undefined;
		this.onpublish = undefined;
		this.onclose = undefined;
		this.onopen = undefined;
	}
	return DataChannel;
})();


XSockets.WebRTC.CallManager = function (ws, settings) {
	var events = settings.events;
	this.call = function (recipient) {
		ws.websocket.trigger("offercontext", {
			PeerId: recipient.PeerId
		});
	};

	this.acceptCall = function (call) {
		events.onAcceptCall(call);
	};
	this.denyCall = function (recipient) {
		ws.websocket.trigger("denycontext", {
			PeerId: recipient.PeerId
		});
	};
	this.endCall = function () {
		ws.websocket.trigger("leavecontext", {});
	};
	ws.websocket.subscribe("contextoffer", events.onCall);
	ws.websocket.subscribe("contextdeny", events.onDenyCall);
	
};

XSockets.WebRTC.Events = {
	onLocalStream: "localstream",
	onRemoteStream: "remotestream",
	onRemoteStreamLost: "removestream",
	onLocalStreamCreated: "streamadded",
	onContextChange: "contextchanged", // Fires when the current context changes
	onContextCreated: "contextcreated", // Fires when a client recives a new context
	onPeerConnectionStarted: "peerconnectionstarted", // Fires when a new RTCPeerConnection is initialized
	onPeerConnectionCreated: "peerconnectioncreated", // Fires when a new RTCPeerConnection is created
	onPeerConnectionLost: "peerconnectionlost", // Fires when a RTCPeerConnection is lost
	onDataChannelOpen: "datachannelopen", // Fires when a datachannel is open
	onDataChannelClose: "datachannelclose", // Fires when a datachannes is closed
	onOffer: 'sdoffer',
	onAnswer: 'sdanswer'

};

const GnuralRTC = {}

GnuralRTC.Instance = new XSockets.WebRTC(ws, {sdpExpressions: []});

let reopenCallbackList = [];
GnuralRTC.onReopen = (callback) => {
	reopenCallbackList.push(callback);
}

ws.onReopen((GSocket) => {
	GnuralRTC.Instance = new XSockets.WebRTC(GSocket, {sdpExpressions: []});
	reopenCallbackList.forEach(callback => {
		if (callback && typeof(callback) === 'function') {
			callback(GnuralRTC)
		}
	});
});


export default GnuralRTC;