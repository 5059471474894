import React from 'react';
import { connect } from 'react-redux';
import { setNewMediaDevice, refreshLocalMedia, setShouldConfigureMedia } from '../../Redux/Actions/mediaActions';
import { setAutoAnswer } from '../../Redux/Actions/callActions';

import MediaConfigurationMenuPresentation from './MediaConfigurationMenuPresentation';
import CustomVideo from '../CustomVideo';
import LocalMediaHandler from '../../Utilities/Gnural/LocalMediaHandler';

import style from './MediaConfigurationWizard.module.css';

function mapStateToProps(store) {
	return ({
		audioInputList: store.media.audioInputList,
		audioOutputList: store.media.audioOutputList,
		videoInputList: store.media.videoInputList,
		videoResolutionObject: store.media.videoResolutionObject,
		currentAudioInput: store.media.currentAudioInput,
		currentAudioOutput: store.media.currentAudioOutput,
		currentVideoInput: store.media.currentVideoInput,
		currentVideoResolution: store.media.currentVideoResolution,
		localMediaStreamID: store.media.localMediaStreamID,
		autoAnswer: store.call.AutoAnswer,
		UserId: store.user.UserId,
		isProcessing: store.media.isProcessing,
		headerLogoImage: store.userinterface.headerLogoImage
	});
}

class MediaPanel extends React.Component {
	constructor(props) {
		super(props);
		// Do stuff with binding
		this.onUpdateSelectGeneric = this.onUpdateSelectGeneric.bind(this);
		this.onUpdateVideoResolution = this.onUpdateVideoResolution.bind(this);
		this.onRefreshLocalMedia = this.onRefreshLocalMedia.bind(this);
		this.onToggleAutoAnswer = this.onToggleAutoAnswer.bind(this);
		this.displayProperMessage = this.displayProperMessage.bind(this);
		this.onContinue = this.onContinue.bind(this);
		this.onBack = this.onBack.bind(this);
		this.state = {
			localStream: undefined,
			stage: 'VideoInput'
		};
	}

	componentDidMount() {
		this.setState({
			localStream: LocalMediaHandler.getLocalMediaStream()
		});
	}

	componentDidUpdate(nextProps) {
		if (nextProps.localMediaStreamID !== this.props.localMediaStreamID) {
			this.setState({
				localStream: LocalMediaHandler.getLocalMediaStream()
			});
		}
	}

	onRefreshLocalMedia() {
		this.props.dispatch(refreshLocalMedia());
	}

	onUpdateSelectGeneric(selectType, event) {
		if (!event || !event.target || !selectType) {
			return;
		}
		const currentlySelectedObject = event.target.options[event.target.selectedIndex].value;
		if (!currentlySelectedObject) {
			return;
		}
		const desiredItem = this.props[selectType].filter(objects => {
			return objects.deviceId === currentlySelectedObject;
		});
		if (!desiredItem[0]) {
			return;
		}
		this.props.dispatch(setNewMediaDevice(selectType, desiredItem[0]));
	}

	onUpdateVideoResolution(event) {
		if (!event || !event.target) {
			return;
		}

		const currentlySelectedResolution = event.target.options[event.target.selectedIndex];
		if (!currentlySelectedResolution) {
			return;
		}
		const desiredResolution = this.props.videoResolutionObject[this.props.currentVideoInput.deviceId].filter(
			(resolution) => {
				return resolution.friendlyName === currentlySelectedResolution.value;
			})[0];
		if (!desiredResolution) {
			return;
		}
		this.props.dispatch(setNewMediaDevice('videoResolutionObject', desiredResolution));
	}

	onToggleAutoAnswer() {
		this.props.dispatch(setAutoAnswer(!this.props.autoAnswer));
	}

	onContinue() {
		if (this.state.stage === 'AudioConfiguration') {
			localStorage.setItem('HasConfiguredMedia', true);
			this.props.dispatch(setShouldConfigureMedia(false));
			return;
		}
		this.setState({
			stage: 'AudioConfiguration'
		});
	}

	onBack() {
		if (this.state.stage === 'VideoInput') {
			return;
		}
		this.setState({
			stage: 'VideoInput'
		});
	}

	displayProperMessage() {
		switch(this.state.stage) {
		case 'VideoInput': {
			return (
				<div className={[style.SurfaceWrapper, style.ConfigurationPanelTextWrapper].join(' ')}>
					<div>Please Select the Webcam you would like to use</div>
					<div>If you do not see any Devices, please verify that you have a Webcam connected</div>
					<div>Once you can see yourself, Press Continue</div>
				</div>
			);
		}
		case 'AudioConfiguration': {
			return (
				<div className={[style.SurfaceWrapper, style.ConfigurationPanelTextWrapper].join(' ')}>
					<div> Please Configure your Microphone and Speaker</div>
					<div> If both your Microphone and Speakers are configured correctly, you should now be able to hear yourself</div>
					<div> Once everything sounds correct, Press Continue</div>
				</div>
			);
		}
		default: {
			return (
				<div className={[style.SurfaceWrapper, style.ConfigurationPanelTextWrapper].join(' ')}>
					<div>Please Press Continue</div>
				</div>
			);
		}
		}
	}

	render() {
		return (
			<div className={style.ConfigurationPanelWrapperOuter}>
				{this.props.headerLogoImage ?
					<div className={style.Header}>
						<div className={style.HeaderLogoWrapper}>
							<img className={style.HeaderLogo} src={this.props.headerLogoImage}/>	
						</div>
					</div>
					:
					null
				}
				<div className={style.ConfigurationPanelWrapperUpper}>
					<div className={style.LocalVideoWrapper}>
						<CustomVideo
							className={style.LocalVideo}
							VideoFramingStyle={style.VideoFraming}
							srcObject={this.state.localStream}
							muted={this.state.stage !== 'AudioConfiguration'}
							shouldContainFraming={true}
							currentAudioOutput={this.props.currentAudioOutput}
						/>
						{this.props.isProcessing ? 
							<div className={style.IsProcessingWrapper}>
								<div className={style.IsProcessingGraphic}>
									<div className={style.IsProcessingGraphicInner}>Loading</div>
								</div>
							</div>
							:
							null
						}
					</div>
					<MediaConfigurationMenuPresentation
						currentVideoInput={this.props.currentVideoInput}
						videoInputList={this.props.videoInputList}
						currentAudioInput={this.props.currentAudioInput}
						audioInputList={this.props.audioInputList}
						currentAudioOutput={this.props.currentAudioOutput}
						audioOutputList={this.props.audioOutputList}
						onUpdateSelectGeneric={this.onUpdateSelectGeneric}
						stage={this.state.stage}
						UserId={this.props.UserId}
					/>
				</div>
				<div className={style.ConfigurationPanelWrapperLower}>
					{this.displayProperMessage()}
					<div className={style.Footer}>
						{this.state.stage !== 'VideoInput' ? <div className={'Button Secondary'} onClick={this.onBack}>Back</div> : <div className={'Button Secondary Inactive'}>Back</div>}
						<div className={'Button Primary'} onClick={this.onContinue}>Continue</div>
					</div>
				</div>
			</div>
		);
	}
}
//<button className='custom-secondary-button' onClick={this.openSettingsModal}> Settings </button>

export default connect(mapStateToProps)(MediaPanel);