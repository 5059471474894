
const configuratorMiddleware = store => next => async action => {
	switch (action.type) {
	case 'ON_RESET_CONFIGURATOR': {
		const computedStyle = getComputedStyle(document.querySelector(':root'));
		
		const lightScalar = computedStyle.getPropertyValue('--Light-Scalar') || 0;
		const veryLightScalar = computedStyle.getPropertyValue('--Very-Light-Scalar') || 0;
		const darkScalar = computedStyle.getPropertyValue('--Dark-Scalar') || 0;

		const alphaLevel = computedStyle.getPropertyValue('--Alpha-Level') || 0;
		const panelAlphaLevel = computedStyle.getPropertyValue('--Panel-Alpha-Level') || 0;
		const shadowAlpha = computedStyle.getPropertyValue('--Shadow-Alpha') || 0;
		const borderAlpha = computedStyle.getPropertyValue('--Border-Alpha') || 0;


		const primaryColorR = computedStyle.getPropertyValue('--Primary-Color-R');
		const primaryColorG = computedStyle.getPropertyValue('--Primary-Color-G');
		const primaryColorB = computedStyle.getPropertyValue('--Primary-Color-B');
		const primaryColorText = computedStyle.getPropertyValue('--Primary-Color-Text').replace('rgb(', '').replace(')', '').split(',');
		const primaryColorTextR = primaryColorText?.[0] ?? 0;
		const primaryColorTextG = primaryColorText?.[1] ?? 0;
		const primaryColorTextB = primaryColorText?.[2] ?? 0;

		const secondaryColorR = computedStyle.getPropertyValue('--Secondary-Color-R');
		const secondaryColorG = computedStyle.getPropertyValue('--Secondary-Color-G');
		const secondaryColorB = computedStyle.getPropertyValue('--Secondary-Color-B');
		const secondaryColorText = computedStyle.getPropertyValue('--Secondary-Color-Text').replace('rgb(', '').replace(')', '').split(',');
		const secondaryColorTextR = secondaryColorText?.[0] ?? 0;
		const secondaryColorTextG = secondaryColorText?.[1] ?? 0;
		const secondaryColorTextB = secondaryColorText?.[2] ?? 0;

		const surfaceColorR = computedStyle.getPropertyValue('--Surface-Color-R');
		const surfaceColorG = computedStyle.getPropertyValue('--Surface-Color-G');
		const surfaceColorB = computedStyle.getPropertyValue('--Surface-Color-B');
		const surfaceColorText = computedStyle.getPropertyValue('--Surface-Color-Text').replace('rgb(', '').replace(')', '').split(',');
		const surfaceColorTextR = surfaceColorText?.[0] ?? 0;
		const surfaceColorTextG = surfaceColorText?.[1] ?? 0;
		const surfaceColorTextB = surfaceColorText?.[2] ?? 0;
		
		const errorColorR = computedStyle.getPropertyValue('--Error-Color-R');
		const errorColorG = computedStyle.getPropertyValue('--Error-Color-G');
		const errorColorB = computedStyle.getPropertyValue('--Error-Color-B');
		const errorColorText = computedStyle.getPropertyValue('--Error-Color-Text').replace('rgb(', '').replace(')', '').split(',');
		const errorColorTextR = errorColorText?.[0] ?? 0;
		const errorColorTextG = errorColorText?.[1] ?? 0;
		const errorColorTextB = errorColorText?.[2] ?? 0;
		
		const affirmativeColorR = computedStyle.getPropertyValue('--Affirmative-Color-R');
		const affirmativeColorG = computedStyle.getPropertyValue('--Affirmative-Color-G');
		const affirmativeColorB = computedStyle.getPropertyValue('--Affirmative-Color-B');
		const affirmativeColorText = computedStyle.getPropertyValue('--Affirmative-Color-Text').replace('rgb(', '').replace(')', '').split(',');
		const affirmativeColorTextR = affirmativeColorText?.[0] ?? 0;
		const affirmativeColorTextG = affirmativeColorText?.[1] ?? 0;
		const affirmativeColorTextB = affirmativeColorText?.[2] ?? 0;
		
		const backgroundColor = computedStyle.getPropertyValue('--Background-Color').replace('rgb(', '').replace(')', '').split(',');
		const backgroundColorR = backgroundColor?.[0] ?? 0;
		const backgroundColorG = backgroundColor?.[1] ?? 0;
		const backgroundColorB = backgroundColor?.[2] ?? 0;

		const borderColor = computedStyle.getPropertyValue('--Border-Color').split(',');
		const borderColorR = borderColor?.[0] ?? 0;
		const borderColorG = borderColor?.[1] ?? 0;
		const borderColorB = borderColor?.[2] ?? 0;

		const shadowColor = computedStyle.getPropertyValue('--Shadow-Color').split(',');
		const shadowColorR = shadowColor?.[0] ?? 0;
		const shadowColorG = shadowColor?.[1] ?? 0;
		const shadowColorB = shadowColor?.[2] ?? 0;
		
		const standbyColor = computedStyle.getPropertyValue('--Standby-Color').split(',');
		const standbyColorR = standbyColor?.[0] ?? 0;
		const standbyColorG = standbyColor?.[1] ?? 0;
		const standbyColorB = standbyColor?.[2] ?? 0;


		const screeningColor = computedStyle.getPropertyValue('--Screening-Color').split(',');
		const screeningColorR = screeningColor?.[0] ?? 0;
		const screeningColorG = screeningColor?.[1] ?? 0;
		const screeningColorB = screeningColor?.[2] ?? 0;

		const offAirColor = computedStyle.getPropertyValue('--Off-Air-Color').split(',');
		const offAirColorR = offAirColor?.[0] ?? 0;
		const offAirColorG = offAirColor?.[1] ?? 0;
		const offAirColorB = offAirColor?.[2] ?? 0;

		const offAirColorText = computedStyle.getPropertyValue('--Off-Air-Color-Text').replace('rgb(', '').replace(')', '').split(',');
		const offAirColorTextR = offAirColorText?.[0] ?? 0;
		const offAirColorTextG = offAirColorText?.[1] ?? 0;
		const offAirColorTextB = offAirColorText?.[2] ?? 0;

		const onAirColor = computedStyle.getPropertyValue('--On-Air-Color').split(',');
		const onAirColorR = onAirColor?.[0] ?? 0;
		const onAirColorG = onAirColor?.[1] ?? 0;
		const onAirColorB = onAirColor?.[2] ?? 0;

		const onAirColorText = computedStyle.getPropertyValue('--On-Air-Color-Text').replace('rgb(', '').replace(')', '').split(',');
		const onAirColorTextR = onAirColorText?.[0] ?? 0;
		const onAirColorTextG = onAirColorText?.[1] ?? 0;
		const onAirColorTextB = onAirColorText?.[2] ?? 0;

		const state = store.getState();

		const updatedState = {
			lightScalar,
			veryLightScalar,
			darkScalar,
			alphaLevel,
			panelAlphaLevel,
			shadowAlpha,
			borderAlpha,
			primaryColorR,
			primaryColorG,
			primaryColorB,
			primaryColorTextR,
			primaryColorTextG,
			primaryColorTextB,
			secondaryColorR,
			secondaryColorG,
			secondaryColorB,
			secondaryColorTextR,
			secondaryColorTextG,
			secondaryColorTextB,
			surfaceColorR,
			surfaceColorG,
			surfaceColorB,
			surfaceColorTextR,
			surfaceColorTextG,
			surfaceColorTextB,
			errorColorR,
			errorColorG,
			errorColorB,
			errorColorTextR,
			errorColorTextG,
			errorColorTextB,
			affirmativeColorR,
			affirmativeColorG,
			affirmativeColorB,
			affirmativeColorTextR,
			affirmativeColorTextG,
			affirmativeColorTextB,
			backgroundColorR,
			backgroundColorG,
			backgroundColorB,
			borderColorR,
			borderColorG,
			borderColorB,
			shadowColorR,
			shadowColorG,
			shadowColorB,
			standbyColorR,
			standbyColorG,
			standbyColorB,
			screeningColorR,
			screeningColorG,
			screeningColorB,
			offAirColorR,
			offAirColorG,
			offAirColorB,
			offAirColorTextR,
			offAirColorTextG,
			offAirColorTextB,
			onAirColorR,
			onAirColorG,
			onAirColorB,
			onAirColorTextR,
			onAirColorTextG,
			onAirColorTextB,
			outOfShowImage: state.userinterface.outOfShowImage,
			screeningShowImage: state.userinterface.screeningShowImage,
			studioShowImage: state.userinterface.studioShowImage,
			completedShowImage: state.userinterface.completedShowImage,
			headerLogoImage: state.userinterface.headerLogoImage,
			CustomShowField1IsVisible: state.userinterface.CustomShowField1IsVisible,
			CustomShowField1Label: state.userinterface.CustomShowField1Label,
			CustomShowField2IsVisible: state.userinterface.CustomShowField2IsVisible,
			CustomShowField2Label: state.userinterface.CustomShowField2Label,
			CustomShowField3IsVisible: state.userinterface.CustomShowField3IsVisible,
			CustomShowField3Label: state.userinterface.CustomShowField3Label,
			CustomShowField4IsVisible: state.userinterface.CustomShowField4IsVisible,
			CustomShowField4Label: state.userinterface.CustomShowField4Label,
			CustomShowField5IsVisible: state.userinterface.CustomShowField5IsVisible,
			CustomShowField5Label: state.userinterface.CustomShowField5Label,
		};

		next({...action, payload: updatedState});
		break;
	}
	case 'ON_APPLY_CONFIGURATOR': {
		const configuratorState = store.getState()?.configurator;

		if (!configuratorState)
		{
			return next(action);
		}

		const root = document.querySelector(':root');

		let lightScalar = configuratorState.lightScalar ?? 0;
		if (lightScalar) {
			root.style.setProperty('--Light-Scalar', lightScalar);
		}

		let veryLightScalar = configuratorState.veryLightScalar ?? 0;
		if (veryLightScalar) {
			root.style.setProperty('--Very-Light-Scalar', veryLightScalar);
		}

		let darkScalar = configuratorState.darkScalar ?? 0;
		if (darkScalar) {
			root.style.setProperty('--Dark-Scalar', darkScalar);
		}

		let primaryColor = 
		(configuratorState.primaryColorR ?? 0) + ',' + 
		(configuratorState.primaryColorG ?? 0) + ',' +
		(configuratorState.primaryColorB ?? 0);
		if (primaryColor) {
			let splitColor = primaryColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Primary-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Primary-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--Primary-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let primaryColorText = 
		(configuratorState.primaryColorTextR ?? 0) + ',' + 
		(configuratorState.primaryColorTextG ?? 0) + ',' +
		(configuratorState.primaryColorTextB ?? 0);
		if (primaryColorText) {
			root.style.setProperty('--Primary-Color-Text', 'rgb(' + primaryColorText + ')');
		}
		
		let secondaryColor = 
		(configuratorState.secondaryColorR ?? 0) + ',' + 
		(configuratorState.secondaryColorG ?? 0) + ',' +
		(configuratorState.secondaryColorB ?? 0);
		if (secondaryColor) {
			let splitColor = secondaryColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Secondary-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Secondary-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--Secondary-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let secondaryColorText = 
		(configuratorState.secondaryColorTextR ?? 0) + ',' + 
		(configuratorState.secondaryColorTextG ?? 0) + ',' +
		(configuratorState.secondaryColorTextB ?? 0);
		if (secondaryColorText) {
			root.style.setProperty('--Secondary-Color-Text', 'rgb(' + secondaryColorText + ')');
		}
		
		let surfaceColor = 
		(configuratorState.surfaceColorR ?? 0) + ',' + 
		(configuratorState.surfaceColorG ?? 0) + ',' +
		(configuratorState.surfaceColorB ?? 0);
		if (surfaceColor) {
			let splitColor = surfaceColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Surface-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Surface-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--Surface-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let surfaceColorText = 
		(configuratorState.surfaceColorTextR ?? 0) + ',' + 
		(configuratorState.surfaceColorTextG ?? 0) + ',' +
		(configuratorState.surfaceColorTextB ?? 0);
		if (surfaceColorText) {
			root.style.setProperty('--Surface-Color-Text', surfaceColorText);
		}

		let errorColor = 
		(configuratorState.errorColorR ?? 0) + ',' + 
		(configuratorState.errorColorG ?? 0) + ',' +
		(configuratorState.errorColorB ?? 0);
		if (errorColor) {
			let splitColor = errorColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Error-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Error-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--Error-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let errorColorText = 
		(configuratorState.errorColorTextR ?? 0) + ',' + 
		(configuratorState.errorColorTextG ?? 0) + ',' +
		(configuratorState.errorColorTextB ?? 0);
		if (errorColorText) {
			root.style.setProperty('--Error-Color-Text', 'rgb(' + errorColorText + ')');
		}
		
		let affirmativeColor = 
		(configuratorState.affirmativeColorR ?? 0) + ',' + 
		(configuratorState.affirmativeColorG ?? 0) + ',' +
		(configuratorState.affirmativeColorB ?? 0);
		if (affirmativeColor) {
			let splitColor = affirmativeColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Affirmative-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Affirmative-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--AFfirmative-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let affirmativeColorText = 
		(configuratorState.affirmativeColorTextR ?? 0) + ',' + 
		(configuratorState.affirmativeColorTextG ?? 0) + ',' +
		(configuratorState.affirmativeColorTextB ?? 0);
		if (affirmativeColorText) {
			root.style.setProperty('--Affirmative-Color-Text', 'rgb(' + affirmativeColorText + ')');
		}
		
		let backgroundColor = 
		(configuratorState.backgroundColorR ?? 0) + ',' + 
		(configuratorState.backgroundColorG ?? 0) + ',' +
		(configuratorState.backgroundColorB ?? 0);
		if (backgroundColor) {
			root.style.setProperty('--Background-Color', 'rgb(' + backgroundColor + ')');
		}
		
		let borderColor = 
		(configuratorState.borderColorR ?? 0) + ',' + 
		(configuratorState.borderColorG ?? 0) + ',' +
		(configuratorState.borderColorB ?? 0);
		if (borderColor) {
			root.style.setProperty('--Border-Color', borderColor);
		}
		
		let borderAlpha = configuratorState.borderAlpha ?? 0;
		root.style.setProperty('--Border-Alpha', borderAlpha);

		let shadowColor = 
		(configuratorState.shadowColorR ?? 0) + ',' + 
		(configuratorState.shadowColorG ?? 0) + ',' +
		(configuratorState.shadowColorB ?? 0);
		if (shadowColor) {
			root.style.setProperty('--Shadow-Color', shadowColor);
		}
		
		let shadowAlpha = configuratorState.shadowAlpha ?? 0;
		root.style.setProperty('--Shadow-Alpha', shadowAlpha);
		
		let standbyColor = 
		(configuratorState.standbyColorR ?? 0) + ',' + 
		(configuratorState.standbyColorG ?? 0) + ',' +
		(configuratorState.standbyColorB ?? 0);
		if (standbyColor) {
			root.style.setProperty('--Standby-Color', standbyColor);
		}
		
		let screeningColor = 
		(configuratorState.screeningColorR ?? 0) + ',' + 
		(configuratorState.screeningColorG ?? 0) + ',' +
		(configuratorState.screeningColorB ?? 0);
		if (screeningColor) {
			root.style.setProperty('--Screening-Color', screeningColor);
		}
		
		let offAirColor = 
		(configuratorState.offAirColorR ?? 0) + ',' + 
		(configuratorState.offAirColorG ?? 0) + ',' +
		(configuratorState.offAirColorB ?? 0);
		if (offAirColor) {
			root.style.setProperty('--Off-Air-Color', offAirColor);
		}
		
		let offAirColorText = 
		(configuratorState.offAirColorTextR ?? 0) + ',' + 
		(configuratorState.offAirColorTextG ?? 0) + ',' +
		(configuratorState.offAirColorTextB ?? 0);
		if (offAirColorText) {
			root.style.setProperty('--Off-Air-Color-Text', offAirColorText);
		}
		
		let onAirColor = 
		(configuratorState.onAirColorR ?? 0) + ',' + 
		(configuratorState.onAirColorG ?? 0) + ',' +
		(configuratorState.onAirColorB ?? 0);
		if (onAirColor) {
			root.style.setProperty('--On-Air-Color', onAirColor);
		}
		
		let onAirColorText = 
		(configuratorState.onAirColorTextR ?? 0) + ',' + 
		(configuratorState.onAirColorTextG ?? 0) + ',' +
		(configuratorState.onAirColorTextB ?? 0);
		if (onAirColorText) {
			root.style.setProperty('--On-Air-Color-Text', onAirColorText);
		}


		let alphaLevel = configuratorState.alphaLevel ?? 0.0;
		root.style.setProperty('--Alpha-Level', alphaLevel);

		let panelAlphaLevel = configuratorState.panelAlphaLevel ?? 0.0;
		root.style.setProperty('--Panel-Alpha-Level', panelAlphaLevel);

		next({...action, payload: configuratorState});
		break;
	}
	default: next(action);
	}
};

export default configuratorMiddleware;