import GSocket from '../../Utilities/Gnural/websockets';

export function acceptIncomingCall(PeerId, Context, UserId, DispName) {
	return ({
		type: 'ON_INCOMING_CALL_FULFILLED',
		payload: {
			PeerId,
			Context,
			UserId,
			DispName
		}
	});
}

export function denyIncomingCall(PeerId, UserId) {
	return (dispatch) => {
		GSocket.websocket.publish('DenyContext', {recipient: PeerId});
		dispatch({
			type: 'ON_INCOMING_CALL_REJECTED',
			payload: {
				PeerId,
				UserId
			}
		});
	};
}

export function callUserId(UserId, DispName) {
	return (dispatch) => {
		if (!UserId) {
			dispatch({type: 'ON_CALL_USER_ID_REJECTED', error: 'Invalid UserId'});
			return;
		}
		GSocket.websocket.publish('CallByUserId', {UserId});
		GSocket.websocket.publish('GetAvatar', {UserId});
		dispatch({
			type: 'ON_CALL_USER_ID_PENDING', payload: {UserId, DispName}
		});
	};
}

export function dropUserId(UserId) {
	return (dispatch) => {
		GSocket.websocket.publish('DropByUserId', { UserId });
		dispatch({
			type: 'ON_DROP_USER_ID_PENDING', payload: { UserId }
		});
	};
}

export function setContext(context) {
	return ({
		type: 'ON_SET_CONTEXT_PENDING',
		payload: context
	});
}

export function disconnectSelf() {
	return ({
		type: 'ON_DISCONNECT_SELF_PENDING'
	});
}

export function setAutoAnswer(AutoAnswerState) {
	return ({
		type: 'SET_AUTO_ANSWER',
		payload: AutoAnswerState
	});
}
