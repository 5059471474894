import React from 'react';
import { connect } from 'react-redux';
import ChatTabs from './ChatTabs';
import ChatDisplay from './ChatDisplay';
import './ChatWindow.css';

function mapStateToProps(store) {
	return {
		isChatWindowActive: store.chat.isChatWindowActive,
		isChatWindowMinimized: store.chat.isChatWindowMinimized
	};
}

class ChatWindow extends React.Component {
	constructor(props) {
		super(props);

		this.displayProperWindow = this.displayProperWindow.bind(this);
	}

	displayProperWindow() {
		if (!this.props.isChatWindowActive || this.props.isChatWindowMinimized) {
			return null;
		}
		return (
			<div className={'ChatWindowWrapperOuter'}>
				<ChatTabs/>
				<ChatDisplay/>
			</div>
		);
	}

	render() {
		return this.displayProperWindow(); 
	}
}

export default connect(mapStateToProps)(ChatWindow);