import React from 'react';
import TextField from '../../TextField';
import genericStyle from '../PanelGeneric.module.css';

function LoginPresentation(props) {
	return (
		<form className={'IsolatedInputWrapper'} onSubmit={props.onLoginSubmit}>
			<TextField
				label='Username'
				value={props.loginUsername}
				onChange={props.onLoginUsernameChange}
				placeholder={'Enter your Username...'}
				autoComplete='username'
			/>
			<TextField
				label={'Password'}
				type='password'
				value={props.loginPassword}
				onChange={props.onLoginPasswordChange}
				placeholder={'Enter your Password...'}
				autoComplete='current-password'
			/>
			<div className={genericStyle.Footer}>
				<button className='Button Primary' type='submit'><div>Login</div></button>
			</div>
		</form>
	);
}

export default LoginPresentation;