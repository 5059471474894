import React from 'react';
import { connect } from 'react-redux';
import style from './RemoteVideoPanel.module.css';

function mapStateToProps(store) {
	return {
		inShow: store.callin.inShow,
		CallState: store.callin.CallState,
		Message: store.callin.Message,
		remotePeerID: store.media.remotePeerID,
		wasInStudioQueue: store.callin.wasInStudioQueue,
		wasInShow: store.callin.wasInShow,
		ShowEmbedUrl: store.callin.ShowEmbedUrl,
		ShowQueueOverlay: store.userinterface.ShowQueueOverlay,
		hideRightOverlays: store.userinterface.hideRightOverlays,
		outOfShowImage: store.userinterface.outOfShowImage,
		screeningShowImage: store.userinterface.screeningShowImage,
		studioShowImage: store.userinterface.studioShowImage,
		completedShowImage: store.userinterface.completedShowImage
	};
}


class ShowStateInformationPresentation extends React.Component {
	constructor(props) {
		super(props);

		this.getProperDisplay = this.getProperDisplay.bind(this);
		this.formatShowColumns = this.formatShowColumns.bind(this);
		this.getBackgroundImage = this.getBackgroundImage.bind(this);
	}

	formatShowColumns() {
		if (this.props.remotePeerID) {
			return null;
		}
		return (
			<div key={this.props.CallState + '-ShowStateWrapper'} className={[style.ShowStateWrapperDynamic, (this.props.hideRightOverlays && this.getBackgroundImage() ? style.Hidden : null) ].join(' ')}>
				<div className={[style.ShowStateWrapperColumnOuter, (this.props.CallState === 'Screening' ? style.active : null)].join(' ')}>
					<div className={[style.ShowStateWrapperColumnInner, (this.props.CallState === 'Screening' ? style.active : style.dim)].join(' ')}>
						<div className={style.Header}>
							<div>
								Screening
							</div>
							<div>
								Phase
							</div>
						</div>
						<div className={style.Body}>
							{this.props.CallState === 'Screening' ? 
								<React.Fragment>
									<div>
										Wait for a Call Screener
									</div>
									<div>
										Queue Position:
									</div>
									<div className={style.PositionIndicator}>
										{this.props.Message}
									</div>
								</React.Fragment>
								:
								<div className={style.Completed}>Completed</div>
							}
						</div>
					</div>
				</div>
				<div className={[style.ColumnDivide, (this.props.CallState === 'Screening' || !this.props.wasInStudioQueue ? null : style.active)].join(' ')}>
					<div></div>
				</div>
				<div className={[style.ShowStateWrapperColumnOuter, (this.props.CallState === 'Studio' ? style.active : null)].join(' ')}>
					<div className={[style.ShowStateWrapperColumnInner, (this.props.CallState === 'Studio' ? style.active : (this.props.wasInStudioQueue ? style.dim : null))].join(' ')}>
						<div className={style.Header}>
							<div>Studio</div>
							<div>Phase</div>
						</div>
						<div className={style.Body}>
							{this.props.CallState !== 'Studio' && this.props.wasInStudioQueue ? 
								<div className={style.Completed}>Completed</div> 
								:
								<React.Fragment>
									<div>
										Wait for the Studio Operator to
									</div>
									<div>
										Bring you into the Show
									</div>
								</React.Fragment>
							}
						</div>
					</div>
				</div>
				<div className={[style.ColumnDivide, (this.props.wasInShow ? style.active : null)].join(' ')}>
					<div></div>
				</div>
				<div className={[style.ShowStateWrapperColumnOuter, (this.props.CallState === 'Show' ? style.active : null)].join(' ')}>
					<div className={[style.ShowStateWrapperColumnInner, (this.props.CallState === 'Show' ? style.active : (this.props.wasInShow ? style.dim : null))].join(' ')}>
						<div className={style.Header}>
							<div>
								Show
							</div>
							<div>
								Phase
							</div>
						</div>
						<div className={style.Body}>
							{this.props.CallState === 'Show' ? 
								<div>Please Wait</div>
								:
								(this.props.wasInShow ?
									<div className={style.Completed}>Completed</div>
									:
									<div>
										Participate in the Show
									</div>
								) 
							}
						</div>
					</div>
				</div>
				<div className={style.ColumnSpacer}>
				</div>
				<div className={[style.ShowStateWrapperColumnOuter, (this.props.CallState === 'Finished' ? style.active : null)].join(' ')}>
					<div className={[style.ShowStateWrapperColumnInner, (this.props.CallState === 'Finished' ? style.active : null)].join(' ')}>
						<div className={style.Header}>
							<div>
								Finished
							</div>
							<div>
								Phase
							</div>
						</div>
						<div className={style.Body}>
							<div>
								You are now Finished, thank you for joining!
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}

	getProperDisplay() {
		if (!this.props.CallState) {
			return null;
		}
		if (this.props.inShow) {
			switch(this.props.CallState) {
			case 'Screening': {
				return (
					this.formatShowColumns()
				);
			}
			case 'Studio': {
				return (
					this.formatShowColumns()
				);
			}
			case 'Finished': {
				return (
					this.formatShowColumns()
				);
			}
			case 'Show': {
				return (
					this.formatShowColumns()
				);
			}
			case 'Blocked': {
				return (
					<div className={[style.ShowStateWrapper, style.Blocked].join(' ')}>
						<div>BLOCKED</div>
					</div>
				);
			}
			case 'OnAir': {
				return (
					<div className={[style.ShowStateWrapper, style.OnAir].join(' ')}>
						<div>ON-AIR</div>
					</div>
				);
			}
			case 'OffAir': {
				return (
					<div className={[style.ShowStateWrapper, style.OffAir].join(' ')}>
						<div>OFF-AIR</div>
					</div>
				);
			}
			default:
				return null;
			}
		}
		switch(this.props.CallState) {
		case 'OnAir': {
			return (
				<div className={[style.ShowStateWrapper, style.OnAir].join(' ')}>
					<div>ON-AIR</div>
				</div>
			);
		}
		case 'OffAir': {
			return (
				<div className={[style.ShowStateWrapper, style.OffAir].join(' ')}>
					<div>OFF-AIR</div>
				</div>
			);
		}
		default:
			return null;
		}
	}

	getBackgroundImage() {
		if (this.props.remotePeerID) { return null; }

		let desiredImage = this.props.outOfShowImage ?? '';

		switch(this.props.CallState) {
		case 'Screening': {
			desiredImage = this.props.screeningShowImage;
			break;
		}
		case 'Studio': {
			desiredImage = this.props.studioShowImage;
			break;
		}
		case 'Show': {
			desiredImage = '';
			break;
		}
		case 'Finished': {
			desiredImage = this.props.completedShowImage;
			break;
		}
		case 'Blocked': {
			desiredImage = '';
			break;
		}
		default: break;
		}
		return desiredImage;
	}

	render() {
		return (
			<React.Fragment>
				{ this.getBackgroundImage() ? 
					<div className={style.CustomImageWrapper}>
						<img className={style.CustomImage} src={this.getBackgroundImage()}/>
					</div>
					:
					null
				}
				{this.props.ShowQueueOverlay ? this.getProperDisplay() : null}
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(ShowStateInformationPresentation);

