import React from 'react';
import { connect } from 'react-redux';
import style from './Polling.module.css';
import QuestionField from './QuestionField';
import { SetNewPollingQuestionResponse, SetPollingQuestionResponse } from '../../Redux/Actions/pollingActions';

function mapStateToProps(store) {
	return ({
		...store.polling,
	});
}

class Polling extends React.Component {
	constructor(props) {
		super(props);

		this.onSelectResponse = this.onSelectResponse.bind(this);
		this.canSubmit = this.canSubmit.bind(this);
		this.onSubmitResponses = this.onSubmitResponses.bind(this);
		this.onHandleScroll = this.onHandleScroll.bind(this);
		
		this.InnerWrapperRef = React.createRef();

		this.state = {
			displayScrollIndicatorBottom: false,
			displayScrollIndicatorTop: true 
		};
	}

	componentDidMount() {
		this.InnerWrapperRef.current.addEventListener('scroll', this.onHandleScroll);
		this.onHandleScroll();
	}
	
	componentDidUpdate() {
		this.onHandleScroll();
	}

	componentWillUnmount() {
		this.InnerWrapperRef.current.removeEventListener('scroll', this.onHandleScroll);
	}
	
	onHandleScroll() {
		let element = this.InnerWrapperRef.current;

		let newIndicatorTopState = (element.scrollTop != 0);
		let newIndicatorBottomState = (element.scrollHeight - element.scrollTop !== element.clientHeight);
		
		if (newIndicatorTopState !== this.state.displayScrollIndicatorTop ||
			newIndicatorBottomState != this.state.displayScrollIndicatorBottom)
		{
			this.setState({
				...this.state,
				displayScrollIndicatorBottom: newIndicatorBottomState,
				displayScrollIndicatorTop: newIndicatorTopState
			});
		}
	}

	onSelectResponse(FieldId) {
		return () => {
			if (!FieldId) {
				return;
			}
			if (this.props.NewUserResponses.includes(FieldId)) {
				this.props.dispatch(SetNewPollingQuestionResponse(this.props.NewUserResponses.filter(fieldId => fieldId !== FieldId)));
				return;
			}
			if (!this.props.IsMultiselect) {
				this.props.dispatch(SetNewPollingQuestionResponse([FieldId]));
				return;
			}
			this.props.dispatch(SetNewPollingQuestionResponse([...this.props.NewUserResponses, FieldId]));
		};
	}

	canSubmit() {
		if (this.props.UserResponses.length !== this.props.NewUserResponses.length) {
			return true;
		}
		
		for (let index = 0; index < this.props.UserResponses.length; index ++)
		{
			if (this.props.UserResponses[index] !== this.props.NewUserResponses[index]) {
				return true;
			}
		}
		return false;
	}

	onSubmitResponses() {
		if (!this.canSubmit()) {
			return;
		}
		this.props.dispatch(SetPollingQuestionResponse(this.props.NewUserResponses));
	}

	render() {
		return (
			<div className={style.SurfaceWrapper} ref={this.InnerWrapperRef}>
				<div className={[style.BackgroundWrapper].join(' ')}>
					<div className={style.Header}>{this.props.QuestionText}</div>
				</div>
				<div className={style.PanelWrapper}>
					{this.props.Fields.map(field => 
						<QuestionField
							{...field}
							onSelectResponse={this.onSelectResponse(field.QuestionFieldId)}
							IsNumbered={this.props.IsNumbered}
							IsSelected={this.props.NewUserResponses.includes(field.QuestionFieldId)}
							key={'Question-Field-' + field.QuestionFieldId}
						/>)}
				</div>
				<div className={style.Footer}>
					<div className={'Button Primary' + (this.canSubmit() ? '' : ' inactive')} onClick={this.onSubmitResponses}> Submit </div>
				</div>
				<div className={[style.ScrollShadow, style.Top, (this.state.displayScrollIndicatorTop ? style.Active : '')].join(' ')}>▲</div>
				<div className={[style.ScrollShadow, style.Bottom, (this.state.displayScrollIndicatorBottom ? style.Active : '')].join(' ')}>▼</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(Polling);