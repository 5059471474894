import React from 'react';
import style from './PermissionScreen.module.css';

class PermissionScreen extends React.Component {
	render() {
		return (
			<div className={[style.MediaPermissionWrapperOuter, style.BrushedMetalGradient].join(' ')}>
				<div className={[style.MediaPermissionWrapperInner, style.NoiseBackground].join(' ')}>
					<div className={[style.Primary, style.Error].join(' ')}>No Media Permission Given</div>
					<div className={[style.Secondary, style.Error].join(' ')}>Please allow this Website to use your Webcam and Microphone and Refresh this Page</div>
				</div>
			</div>
		);
	}
}

export default PermissionScreen;