function configurator(state= {
	showWebsiteConfigurator: false,
	clientSite: window.location.host,
	showName: '',
	lightScalar: 0,
	veryLightScalar: 0,
	darkScalar: 0,
	primaryColorR: 0,
	primaryColorG: 0,
	primaryColorB: 0,
	primaryColorTextR: 0,
	primaryColorTextG: 0,
	primaryColorTextB: 0,
	secondaryColorR: 0,
	secondaryColorG: 0,
	secondaryColorB: 0,
	secondaryColorTextR: 0,
	secondaryColorTextG: 0,
	secondaryColorTextB: 0,
	surfaceColorR: 0,
	surfaceColorG: 0,
	surfaceColorB: 0,
	surfaceColorTextR: 0,
	surfaceColorTextG: 0,
	surfaceColorTextB: 0,
	errorColorR: 0,
	errorColorG: 0,
	errorColorB: 0,
	errorColorTextR: 0,
	errorColorTextG: 0,
	errorColorTextB: 0,
	affirmativeColorR: 0,
	affirmativeColorG: 0,
	affirmativeColorB: 0,
	affirmativeColorTextR: 0,
	affirmativeColorTextG: 0,
	affirmativeColorTextB: 0,
	backgroundColorR: 0,
	backgroundColorG: 0,
	backgroundColorB: 0,
	borderColorR: 0,
	borderColorG: 0,
	borderColorB: 0,
	alphaLevel: 0,
	panelAlphaLevel: 0,
	shadowColorR: 0,
	shadowColorG: 0,
	shadowColorB: 0,
	shadowAlpha: 0,
	borderAlpha: 0,
	standbyColorR: 0,
	standbyColorG: 0,
	standbyColorB: 0,
	screeningColorR: 0,
	screeningColorG: 0,
	screeningColorB: 0,
	offAirColorR: 0,
	offAirColorG: 0,
	offAirColorB: 0,
	onAirColorR: 0,
	onAirColorG: 0,
	onAirColorB: 0,
	offAirColorTextR: 0,
	offAirColorTextG: 0,
	offAirColorTextB: 0,
	onAirColorTextR: 0,
	onAirColorTextG: 0,
	onAirColorTextB: 0,
	outOfShowImage: '',
	screeningShowImage: '',
	studioShowImage: '',
	completedShowImage: '',
	headerLogoImage: 'https://www.gnuralnet.com/hubfs/GNU-Website-Gen3/Company-Logos/GnuralNet-Logo-II-72H-1.png',
	CustomShowField1IsVisible: false,
	CustomShowField1Label: 'Email',
	CustomShowField2IsVisible: false,
	CustomShowField2Label: 'Phone',
	CustomShowField3IsVisible: false,
	CustomShowField3Label: 'Twitter',
	CustomShowField4IsVisible: false,
	CustomShowField4Label: 'Facebook',
	CustomShowField5IsVisible: false,
	CustomShowField5Label: 'Location'
}, action) {
	switch (action.type) {
	case 'ON_RESET_CONFIGURATOR': {
		return {
			...state,
			...action.payload
		};
	}
	case 'ON_SET_CONFIGURATOR_FIELD_FULFILLED': {
		return {
			...state,
			[action.payload.fieldName]: action.payload.value
		};
	}
	case 'ON_TOGGLE_CONFIGURATOR_SHOW_FIELD_VISIBILITY_FULFILLED': {
		return {
			...state,
			['CustomShowField' + action.payload + 'IsVisible']: !state['CustomShowField' + action.payload + 'IsVisible']
		};
	}
	case 'ON_SET_CONFIGURATOR_SHOW_NAME': {
		return {
			...state,
			showName: action.payload
		};
	}
	case 'ON_SET_CONFIGURATOR_VISIBILITY': {
		return {
			...state,
			showWebsiteConfigurator: action.payload
		};
	}
	default: return state;
	}
} 

export default configurator;