import * as React from 'react';

const SvgComponent = (props) => (
	<svg width={512} height={512} viewBox={'0 0 512 512'} xmlns='http://www.w3.org/2000/svg'
		onClick={props.onClick}
		className={props.className}
	>
		<path d='M64 144h226.75a48 48 0 0 0 90.5 0H448a16 16 0 0 0 0-32h-66.75a48 48 0 0 0-90.5 0H64a16 16 0 0 0 0 32ZM448 368h-66.75a48 48 0 0 0-90.5 0H64a16 16 0 0 0 0 32h226.75a48 48 0 0 0 90.5 0H448a16 16 0 0 0 0-32ZM448 240H221.25a48 48 0 0 0-90.5 0H64a16 16 0 0 0 0 32h66.75a48 48 0 0 0 90.5 0H448a16 16 0 0 0 0-32Z' stroke={props.Color} fill={props.Color} />
	</svg>
);

export default SvgComponent;
