import React from 'react';
import LoginPresentation from './LoginPresentation';
import { connect } from 'react-redux';
import { logInUser } from '../../../../Redux/Actions/loginActions';

import genericStyle from '../PanelGeneric.module.css';

function mapStateToProps(store) {
	return {
		user: {
			loginStatus: store.user.loginStatus,
			error: {
				loginError: store.user.error.loginError
			}
		}
	};
}

class LoginPanel extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			loginUsername: '',
			loginPassword: '',
		};
		this.onLoginSubmit = this.onLoginSubmit.bind(this);
		this.onLoginUsernameChange = this.onLoginUsernameChange.bind(this);
		this.onLoginPasswordChange = this.onLoginPasswordChange.bind(this);
	}

	onLoginSubmit(event) {
		event.preventDefault();
		this.props.dispatch(logInUser(this.state.loginUsername, this.state.loginPassword));
	}

	onLoginUsernameChange(event) {
		this.setState({
			loginUsername: event.target.value
		});
	}

	onLoginPasswordChange(event) {
		this.setState({
			loginPassword: event.target.value
		});
	}

	render() {
		return ( 
			<div className={genericStyle.PanelWrapper}>
				<div className={genericStyle.BackgroundWrapper}>
					<div className={genericStyle.Header}>Login</div>
					<div className={genericStyle.Error}>{this.props.user.error.loginError}</div>
				</div>
				<div className={genericStyle.SurfaceWrapper}>
					<LoginPresentation {...{
						onLoginSubmit: this.onLoginSubmit,
						onLoginUsernameChange: this.onLoginUsernameChange,
						onLoginPasswordChange: this.onLoginPasswordChange,
						loginUsername: this.state.loginUsername,
						loginPassword: this.state.loginPassword,
					}}/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(LoginPanel);