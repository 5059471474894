import * as React from 'react';

const SvgComponent = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='-150 -150 760.001 760.001'
		style={{
			enableBackground: 'new 0 0 490.001 490.001',
		}}
		xmlSpace='preserve'
		onClick={props.onClick}
		className={props.className}
	>
		<path d='M450 0H40C17.945 0 0 17.944 0 40v280c0 22.056 17.944 40 40 40h235v120a10 10 0 0 0 17.348 6.783L409.378 360H450c22.056 0 40-17.944 40-40V40c0-22.056-17.943-40-40-40zm20 320c0 11.028-8.972 20-20 20h-45a10.003 10.003 0 0 0-7.348 3.217L295 454.423V350c0-5.523-4.478-10-10-10H40c-11.028 0-20-8.972-20-20V40c0-11.028 8.972-20 20-20h410c11.028 0 20 8.972 20 20v280z' stroke={props.Color} fill={props.Color} />
		<path d='M215.001 205v-50c0-44.112-35.888-80-80-80s-80 35.888-80 80v50c0 44.112 35.888 80 80 80 18.459 0 35.464-6.301 49.016-16.842l23.913 23.913 14.143-14.143-23.913-23.913c10.54-13.551 16.841-30.556 16.841-49.015zm-20 0c0 12.939-4.131 24.924-11.123 34.734l-26.806-26.806-14.143 14.143 26.806 26.806C159.925 260.87 147.94 265 135.001 265c-33.084 0-60-26.916-60-60v-50c0-33.084 26.916-60 60-60s60 26.916 60 60v50zM255.001 95h80v20h-80zM350 95h60v20h-60zM255.001 145h180v20h-180zM255.001 195h180v20h-180zM255.001 245h180v20h-180z' stroke={props.Color} fill={props.Color}/>
	</svg>
);

export default SvgComponent;