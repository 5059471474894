import GSocket from '../../Utilities/Gnural/websockets';

export function openChat(UserId, DispName) {
	return ({
		type: 'OPEN_CHAT',
		payload: {
			UserId,
			DispName
		}
	});
}

export function closeChat(UserId) {
	return ({
		type: 'CLOSE_CHAT',
		payload: {
			UserId
		}
	});
}

export function updateChatMessage(Message) {
	return ({
		type: 'UPDATE_CHAT_MESSAGE',
		payload: {
			Message
		}
	});
}

export function textToPeer(SenderUserId, SenderDisplayName, RecipientUserId, Message) {
	return (dispatch) => {
		GSocket.websocket.publish('TextToPeer', {
			RecipientUserId,
			SenderUserId,
			SenderDisplayName,
			Message
		});
		dispatch({
			type: 'ON_TEXT_TO_PEER_SENT',
			payload: {
				RecipientUserId,
				SenderUserId,
				SenderDisplayName,
				Message
			}
		});
	};
}

export function toggleMinimizeChatWindow() {
	return ({
		type: 'TOGGLE_MINIMIZE_CHAT_WINDOW'
	});
}