
const localStorageMiddleware = store => next => async action => {
	switch(action.type) {
	case 'ON_ENTER_SHOW_PENDING': {
		if (!action.payload) {
			return next(action);
		}
		let identifier = 'Anonymous';
		const state = store.getState();
		if (state.user.UserId) {
			identifier = state.user.UserId;
		}
		if (action.payload.CookieGuid) {
			localStorage.setItem(identifier+'-CallInGUID', action.payload.CookieGuid);
		}
		if (action.payload.Name) {
			localStorage.setItem(identifier+'-CallIn-LastNameUsed', action.payload.Name);
		}
		if (action.payload.Email) {
			localStorage.setItem(identifier+'-CallIn-LastEmailUsed', action.payload.Email);
		}
		if (action.payload.Phone) {
			localStorage.setItem(identifier+'-CallIn-LastPhoneUsed', action.payload.Phone);
		}
		if (action.payload.Twitter) {
			localStorage.setItem(identifier+'-CallIn-LastTwitterUsed', action.payload.Twitter);
		}
		if (action.payload.Facebook) {
			localStorage.setItem(identifier+'-CallIn-LastFacebookUsed', action.payload.Facebook);
		}
		if (action.payload.GeoLocation) {
			localStorage.setItem(identifier+'-CallIn-LastLocationUsed', action.payload.GeoLocation);
		}
		return next(action);
	}
	case 'ON_CONTEXT_CREATED_FULFILLED': {
		if (!action.payload) {
			return next(action);
		}
		const identifier = action.payload.UserId || 'Anonymous';
		const CallInInformation = {};
		CallInInformation.CookieGuid = localStorage.getItem(identifier+'-CallInGUID', action.payload.CookieGuid) || action.payload.Context || '';
		CallInInformation.Name = localStorage.getItem(identifier+'-CallIn-LastNameUsed') || action.payload.DispName || '';
		CallInInformation.Email = localStorage.getItem(identifier+'-CallIn-LastEmailUsed') || '';
		CallInInformation.Phone = localStorage.getItem(identifier+'-CallIn-LastPhoneUsed') || '';
		CallInInformation.Twitter = localStorage.getItem(identifier+'-CallIn-LastTwitterUsed') || '';
		CallInInformation.Facebook = localStorage.getItem(identifier+'-CallIn-LastFacebookUsed') || '';
		CallInInformation.GeoLocation = localStorage.getItem(identifier+'-CallIn-LastLocationUsed') || '';
		return next({type: action.type, payload: {...action.payload, CallInInformation}});
	}
	case 'SET_AUTO_ANSWER': {
		const identifier = store.getState().user.UserId;
		localStorage.setItem('AutoAnswer'+identifier, action.payload);
		return next(action);
	}
	case 'LOGIN_FULFILLED': {
		const identifier = action.payload.UserId;
		if (!identifier) {
			return next(action);
		}
		const AutoAnswer = localStorage.getItem('AutoAnswer'+identifier);
		return next({type: action.type, payload: {...action.payload, AutoAnswer: (AutoAnswer === 'true' ? true : false)}});
	}
	case 'TOGGLE_HIDE_LOCAL_VIDEO': {
		const state = store.getState();
		localStorage.setItem('HideLocalVideo', !state.userinterface.hideLocalVideo);
		return next(action);
	}
	case 'TOGGLE_HIDE_LEFT_PANEL': {
		const state = store.getState();
		localStorage.setItem('HideLeftPanel', !state.userinterface.hideLeftPanel);
		return next(action);
	}
	case 'TOGGLE_FIXED_LAYOUT': {
		const state = store.getState();
		localStorage.setItem('FixedLayout', !state.userinterface.fixedLayout);
		return next(action);
	}
	default:
		return next(action);
	}
};

export default localStorageMiddleware;