import React from 'react';
import Main from './Main';
import SignupConfirmation from './SignupConfirmation';
import MediaConfigurationWizard from './MediaConfigurationWizard';
import PermissionScreen from './PermissionScreen';
import Connecting from './Connecting';
import { connect } from 'react-redux';
import { AcquireBrowserInfo, getConfIdFromURL, getParamFromURL } from '../Utilities/Gnural/SessionInformation';
import { setBrowserInformation, setConfId } from '../Redux/Actions/sessionActions';
import { setShouldConfigureMedia } from '../Redux/Actions/mediaActions';
import { setShowEmbedUrl } from '../Redux/Actions/showActions';
import '../Utilities/Gnural/GnuralRTC';
import './App.css';

import LocalMediaHandler from '../Utilities/Gnural/LocalMediaHandler';
import { setCompletedShowImage, setCustomShowField, setEnableAccountFunctionality, setHeaderLogoImage, setOutOfShowImage, setScreeningShowImage, setStudioShowImage } from '../Redux/Actions/userinterfaceActions';
import { resetConfigurator, setConfiguratorVisibility } from '../Redux/Actions/configurator';
import Configurator from './Configurator';



function mapStateToProps(store) {
	return {
		websocket: {
			ConnectionStatus: store.websocket.ConnectionStatus
		},
		user: {
			loginStatus: store.user.loginStatus
		},
		ConfId: store.sessionInformation.ConfId,
		VideoPermission: store.sessionInformation.VideoPermission,
		AudioPermission: store.sessionInformation.AudioPermission,
		ShouldConfigureMedia: store.sessionInformation.ShouldConfigureMedia,
		isMobileDevice: store.sessionInformation.isMobileDevice,
		showWebsiteConfigurator: store.configurator.showWebsiteConfigurator
	};
}

class App extends React.Component {
	constructor(props) {
		super(props);

		this.displayProperScreen = this.displayProperScreen.bind(this);
		this.configureUserSession = this.configureUserSession.bind(this);
	}

	componentDidMount() {
		this.configureUserSession();
	}

	configureUserSession() {
		this.props.dispatch(setBrowserInformation(AcquireBrowserInfo()));
		this.props.dispatch(setShowEmbedUrl(decodeURIComponent(getParamFromURL('embedurl'))));
		LocalMediaHandler.init();
		let confId = getConfIdFromURL();
	

		if (confId) {
			this.props.dispatch(setConfId(confId));
		}
		if (!localStorage.getItem('HasConfiguredMedia')) {
			this.props.dispatch(setShouldConfigureMedia(true));
		}

		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});

		let showWebsiteConfigurator = params.showWebsiteConfigurator ?? false;
		if (showWebsiteConfigurator) { this.props.dispatch(setConfiguratorVisibility(true)); }

		let enableAccountFunctionality = params.enableAccountFunctionality ?? false;
		if (enableAccountFunctionality) { this.props.dispatch(setEnableAccountFunctionality(true)); }
	
		let headerLogoImage = params.headerLogoImage ?? null;
		if (headerLogoImage) { this.props.dispatch(setHeaderLogoImage(headerLogoImage)); }

		let outOfShowImage = params.outOfShowImage ?? null;
		if (outOfShowImage) { this.props.dispatch(setOutOfShowImage(outOfShowImage)); }

		let screeningShowImage = params.screeningShowImage ?? null;
		if (screeningShowImage) { this.props.dispatch(setScreeningShowImage(screeningShowImage)); }

		let studioShowImage = params.studioShowImage ?? null;
		if (studioShowImage) { this.props.dispatch(setStudioShowImage(studioShowImage)); }

		let completedShowImage = params.completedShowImage ?? null;
		if (completedShowImage) { this.props.dispatch(setCompletedShowImage(completedShowImage)); }

		let field1Label = params.field1Label ?? null;
		if (field1Label) {
			this.props.dispatch(setCustomShowField(1, true, field1Label));
		} else {
			this.props.dispatch(setCustomShowField(1, false, ''));
		}
		
		let field2Label = params.field2Label ?? null;
		if (field2Label) {
			this.props.dispatch(setCustomShowField(2, true, field2Label));
		} else {
			this.props.dispatch(setCustomShowField(2, false, ''));
		}
		
		let field3Label = params.field3Label ?? null;
		if (field3Label) {
			this.props.dispatch(setCustomShowField(3, true, field3Label));
		} else {
			this.props.dispatch(setCustomShowField(3, false, ''));
		}
		
		let field4Label = params.field4Label ?? null;
		if (field4Label) {
			this.props.dispatch(setCustomShowField(4, true, field4Label));
		} else {
			this.props.dispatch(setCustomShowField(4, false, ''));
		}
		
		let field5Label = params.field5Label ?? null;
		if (field5Label) {
			this.props.dispatch(setCustomShowField(5, true, field5Label));
		} else {
			this.props.dispatch(setCustomShowField(5, false, ''));
		}

		const root = document.querySelector(':root');

		let lightScalar = params.lightScalar ?? 0;
		if (lightScalar) {
			root.style.setProperty('--Light-Scalar', lightScalar);
		}

		let veryLightScalar = params.veryLightScalar ?? 0;
		if (veryLightScalar) {
			root.style.setProperty('--Very-Light-Scalar', veryLightScalar);
		}

		let darkScalar = params.darkScalar ?? 0;
		if (darkScalar) {
			root.style.setProperty('--Dark-Scalar', darkScalar);
		}

		let primaryColor = params.primaryColor ?? null;
		if (primaryColor) {
			let splitColor = primaryColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Primary-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Primary-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--Primary-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let primaryColorText = params.primaryColorText ?? null;
		if (primaryColorText) {
			root.style.setProperty('--Primary-Color-Text', 'rgb(' + primaryColorText + ')');
		}
		
		let secondaryColor = params.secondaryColor ?? null;
		if (secondaryColor) {
			let splitColor = secondaryColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Secondary-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Secondary-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--Secondary-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let secondaryColorText = params.secondaryColorText ?? null;
		if (secondaryColorText) {
			root.style.setProperty('--Secondary-Color-Text', 'rgb(' + secondaryColorText + ')');
		}
		
		let surfaceColor = params.surfaceColor ?? null;
		if (surfaceColor) {
			let splitColor = surfaceColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Surface-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Surface-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--Surface-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let surfaceColorText = params.surfaceColorText ?? null;
		if (surfaceColorText) {
			root.style.setProperty('--Surface-Color-Text', surfaceColorText);
		}

		let errorColor = params.errorColor ?? null;
		if (errorColor) {
			let splitColor = errorColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Error-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Error-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--Error-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let errorColorText = params.errorColorText ?? null;
		if (errorColorText) {
			root.style.setProperty('--Error-Color-Text', 'rgb(' + errorColorText + ')');
		}
		
		let affirmativeColor = params.affirmativeColor ?? null;
		if (affirmativeColor) {
			let splitColor = affirmativeColor.split(',');
			if (splitColor.length == 3) {
				root.style.setProperty('--Affirmative-Color-R', parseInt(splitColor[0], 10));
				root.style.setProperty('--Affirmative-Color-G', parseInt(splitColor[1], 10));
				root.style.setProperty('--AFfirmative-Color-B', parseInt(splitColor[2], 10));
			}
		}

		let affirmativeColorText = params.affirmativeColorText ?? null;
		if (affirmativeColorText) {
			root.style.setProperty('--Affirmative-Color-Text', 'rgb(' + affirmativeColorText + ')');
		}
		
		let backgroundColor= params.backgroundColor ?? null;
		if (backgroundColor) {
			root.style.setProperty('--Background-Color', 'rgb(' + backgroundColor + ')');
		}
		
		let borderColor= params.borderColor ?? null;
		if (borderColor) {
			root.style.setProperty('--Border-Color', borderColor);
		}
		
		let borderAlpha= params.borderAlpha ?? 0;
		if (borderAlpha) {
			root.style.setProperty('--Border-Alpha', borderAlpha);
		}
		
		let shadowColor= params.shadowColor ?? null;
		if (shadowColor) {
			root.style.setProperty('--Shadow-Color', shadowColor);
		}
		
		let shadowAlpha= params.shadowAlpha ?? 0;
		if (shadowAlpha) {
			root.style.setProperty('--Shadow-Alpha', shadowAlpha);
		}
		
		let standbyColor= params.standbyColor ?? null;
		if (standbyColor) {
			root.style.setProperty('--Standby-Color', standbyColor);
		}
		
		let screeningColor = params.screeningColor ?? null;
		if (screeningColor) {
			root.style.setProperty('--Screening-Color', screeningColor);
		}
		
		let offAirColor = params.offAirColor ?? null;
		if (offAirColor) {
			root.style.setProperty('--Off-Air-Color', offAirColor);
		}
		
		let offAirColorText = params.offAirColorText ?? null;
		if (offAirColorText) {
			root.style.setProperty('--Off-Air-Color-Text', offAirColorText);
		}
		
		let onAirColor = params.onAirColor ?? null;
		if (onAirColor) {
			root.style.setProperty('--On-Air-Color', onAirColor);
		}
		
		let onAirColorText = params.onAirColorText ?? null;
		if (onAirColorText) {
			root.style.setProperty('--On-Air-Color-Text', onAirColorText);
		}

		this.props.dispatch(resetConfigurator());
	}

	componentDidUpdate(nextProps) {
		if (nextProps.isMobileDevice)
		{
			document.body.style.overflow = 'auto';
		}
	}

	displayProperScreen() {
		if (this.props.websocket.ConnectionStatus !== 'CONNECTED') {
			return <Connecting/>;
		}
		if (this.props.ConfId) {
			return <SignupConfirmation/>;
		}
		if (!this.props.VideoPermission || !this.props.AudioPermission) {
			return <PermissionScreen/>;
		}
		if (this.props.ShouldConfigureMedia) {
			return <MediaConfigurationWizard/>;
		}
		return <Main/>;
	}

	render() {
		return (
			<React.Fragment>
				{this.props.showWebsiteConfigurator ? <Configurator/> : null}
				{ this.displayProperScreen() }
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(App);
