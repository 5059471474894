export function resetConfigurator() {
	return {
		type: 'ON_RESET_CONFIGURATOR'
	};
}

export function setConfiguratorRGBField(fieldName, value) 
{
	return (dispatch) => {
		if (value >= 0 && value <= 255)
		{
			return dispatch({
				type: 'ON_SET_CONFIGURATOR_FIELD_FULFILLED',
				payload: {
					fieldName,
					value,
				}
			});
		}
		return dispatch({type: 'ON_SET_CONFIGURATOR_FIELD_REJECTED'});
	};
}

export function setConfiguratorScalarField(fieldName, value) 
{
	return (dispatch) => {
		if (value >= 0)
		{
			return dispatch({
				type: 'ON_SET_CONFIGURATOR_FIELD_FULFILLED',
				payload: {
					fieldName,
					value,
				}
			});
		}
		return dispatch({type: 'ON_SET_CONFIGURATOR_FIELD_REJECTED'});
	};
}

export function setConfiguratorAlphaField(fieldName, value) 
{
	return (dispatch) => {
		if (value >= 0 || value <= 1.0)
		{
			return dispatch({
				type: 'ON_SET_CONFIGURATOR_FIELD_FULFILLED',
				payload: {
					fieldName,
					value,
				}
			});
		}
		return dispatch({type: 'ON_SET_CONFIGURATOR_FIELD_REJECTED'});
	};
}

export function setConfiguratorTextField(fieldName, value) 
{
	return (dispatch) => {
		return dispatch({
			type: 'ON_SET_CONFIGURATOR_FIELD_FULFILLED',
			payload: {
				fieldName,
				value,
			}
		});
	};
}

export function toggleConfiguratorShowFieldVisibility(fieldNumber)
{
	return (dispatch) => {
		if (fieldNumber > 0 && fieldNumber <= 5)
		{
			return dispatch({
				type: 'ON_TOGGLE_CONFIGURATOR_SHOW_FIELD_VISIBILITY_FULFILLED',
				payload: fieldNumber
			});
		}
		return dispatch({
			type: 'ON_TOGGLE_CONFIGURATOR_SHOW_FIELD_VISIBILITY_REJECTED',
		});
	};
}

export function applyConfigurator()
{
	return {
		type: 'ON_APPLY_CONFIGURATOR'
	};
}

export function setConfiguratorShowName(value) {
	return {
		type: 'ON_SET_CONFIGURATOR_SHOW_NAME',
		payload: value
	};
}

export function setConfiguratorVisibility(value) {
	return {
		type: 'ON_SET_CONFIGURATOR_VISIBILITY',
		payload: value ?? false
	};
}