import DEFAULT_AVATAR from '../../Assets/placeholder-LG.png';

const DEFAULT_STATE = {
	MainSearch: [],
	ContactsDirectory: [],
	ContactsGroupId: 0,
	OriginalUser: {UserId: 'UNCONFIRMED', DispName: '', FirstName: '', LastName: '', Email: '', Location: '', Channels: [{UserName: ''}], CompanyName: '', LTAPairs: [], CIMPairs: []},
	ChangedUser: {UserId: 'UNCONFIRMED', DispName: '', FirstName: '', LastName: '', Email: '', Location: '', Channels: [{UserName: ''}], CompanyName: '', LTAPairs: [], CIMPairs: []},
	OriginalAvatar: DEFAULT_AVATAR,
	ChangedAvatar: DEFAULT_AVATAR,
	DefaultAvatar: DEFAULT_AVATAR,
	CallerAvatar: DEFAULT_AVATAR,
	CallerID: 0,
	UserError: '',
	UserMessage: '',
	HasChanged: false
};

function usermanagement(state = {
	...DEFAULT_STATE
}, action) {
	switch(action.type) {
	case 'ON_SEARCH_USERS_MAIN_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {...state, MainSearch: action.payload};
	}
	case 'LOGIN_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {...state, UserError: '', OriginalUser: {...state.OriginalUser, ...action.payload}, ChangedUser: {...state.ChangedUser, ...action.payload}, HasChanged: false};
	}
	case 'LOGIN_ENDED': {
		return {
			...state,
			...DEFAULT_STATE
		};
	}
	case 'ON_GET_AVATAR_FULFILLED': {
		if (!action.payload || !action.payload.UserId) {
			return state;
		}
		let newContactsDirectoryObject = {...state.ContactsDirectory};
		let newMainSearch = {...state.MainSearch};
		let imageData = DEFAULT_AVATAR;
		if (action.payload.Extention && action.payload.ImageBytes) {
			imageData = 'data:image/' + action.payload.Extention.slice(1) + ';base64,' + action.payload.ImageBytes;	
		}
		if (newContactsDirectoryObject[action.payload.UserId]) {
			newContactsDirectoryObject[action.payload.UserId] = {...newContactsDirectoryObject[action.payload.UserId], Avatar: imageData};
		}
		if (newMainSearch[action.payload.UserId]) {
			newMainSearch[action.payload.UserId] = {...newMainSearch[action.payload.UserId], Avatar: imageData};
		}
		let newCallerAvatar = DEFAULT_AVATAR;
		if (state.CallerID === action.payload.UserId) {
			newCallerAvatar = imageData;
		}
		if (action.payload.UserId !== state.OriginalUser.UserId) {
			return {...state, ContactsDirectory: newContactsDirectoryObject, MainSearch: newMainSearch, CallerAvatar: newCallerAvatar};
		}
		return {...state, OriginalAvatar: imageData, ChangedAvatar: imageData, ContactsDirectory: newContactsDirectoryObject, MainSearch: newMainSearch, CallerAvatar: newCallerAvatar};
	}
	case 'ON_ADD_USER_REJECTED': {
		if (!action.error) {
			return state;
		}
		return {...state, UserError: action.error};
	}
	case 'ON_ADD_USER_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {...state, UserError: '', OriginalUser: action.payload, ChangedUser: {...action.payload, LTAPairs: [], CIMPairs: []}, HasChanged: false};
	}
	case 'ON_SET_TEMPORARY_AVATAR_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {...state, ChangedAvatar: action.payload, HasChanged: true};
	}
	case 'ON_SET_AVATAR_FULFILLED': {
		if (!action.payload || action.payload.UserId !== state.OriginalUser.UserId) {
			return state;
		}
		const imageData = 'data:image/' + action.payload.Extention.slice(1) + ';base64,' + action.payload.ImageBytes;
		return {...state, OriginalAvatar: imageData, ChangedAvatar: imageData, HasChanged: false};
	}
	case 'ON_GET_RELAY_SERVERS_FOR_USER_FULFILLED': {
		if (!action.payload || !action.payload.ReqId || (Number.parseInt(action.payload.ReqId) !== state.OriginalUser.UserId)) {
			return state;
		}
		return {...state, OriginalRelayServers: [...action.payload.Lst], ChangedRelayServers: [...action.payload.Lst]};
	}
	case 'ON_GET_AVATAR_REJECTED': {
		if (!action.payload || action.payload.UserId !== state.OriginalUser.UserId) {
			return state;
		}
		return {...state, OriginalAvatar: DEFAULT_AVATAR, ChangedAvatar: DEFAULT_AVATAR};
	}
	case 'ON_SET_SELECTED_USERNAME_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				Channels: [{
					...state.ChangedUser.Channels[0],
					UserName: action.payload.UserName
				}]
			},
			HasChanged: true
		};
	}
	case 'ON_SET_SELECTED_DISPNAME_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				DispName: action.payload.DispName
			},
			HasChanged: true
		};
	}
	case 'ON_SET_SELECTED_EMAIL_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				Email: action.payload.Email
			},
			HasChanged: true
		};
	}
	case 'ON_SET_SELECTED_COMPANYNAME_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				CompanyName: action.payload.CompanyName
			},
			HasChanged: true
		};
	}
	case 'ON_SET_SELECTED_LOCATION_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				Location: action.payload.Location
			},
			HasChanged: true
		};
	}

	case 'ON_SET_SELECTED_PASSWORD_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				Password: action.payload.Password
			},
			HasChanged: true
		};
	}
	case 'ON_SET_SELECTED_MATCHPASSWORD_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				MatchPassword: action.payload.MatchPassword
			},
			HasChanged: true
		};
	}
	case 'ON_TOGGLE_SELECTED_ISLTA_FULFILLED': {
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				isLTA: !state.ChangedUser.isLTA,
				CIMPairs: (state.ChangedUser.isLTA ? [] : state.ChangedUser.CIMPairs)
			},
			HasChanged: true
		};
	}
	case 'ON_TOGGLE_SELECTED_ISCIM_FULFILLED': {
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				isCIM: !state.ChangedUser.isCIM,
				LTAPairs: (state.ChangedUser.isCIM ? [] : state.ChangedUser.LTAPairs)
			},
			HasChanged: true
		};
	}
	case 'ON_ADD_USER_PAIRING_FULFILLED': {
		if (!action.payload || !action.payload.CimUserId || !action.payload.LtaUserId
			|| !action.payload.CIMUser || !action.payload.LTAUser) {
			return state;
		}
		if (action.payload.CimUserId === state.OriginalUser.UserId) {
			if (state.ChangedUser.LTAPairs.filter(pairs => pairs.UserId === action.payload.LtaUserId).length) {
				return state;
			}
			return ({
				...state, 
				ChangedUser: {
					...state.ChangedUser,
					LTAPairs: [...state.ChangedUser.LTAPairs, action.payload.LTAUser]
				},
				HasChanged: false
			});
		}
		if (action.payload.LtaUserId === state.OriginalUser.UserId) {
			if (state.ChangedUser.CIMPairs.filter(pairs => pairs.UserId === action.payload.CimUserId).length) {
				return state;
			}
			return ({
				...state,
				ChangedUser: {
					...state.ChangedUser,
					CIMPairs: [...state.ChangedUser.CIMPairs, action.payload.CIMUser]
				},
				HasChanged: false
			});
		}
		return state;
	}
	
	case 'ON_REMOVE_LTA_PAIR_FULFILLED': {
		if (!action.payload || !action.payload.UserId) {
			return;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				LTAPairs: state.ChangedUser.LTAPairs.filter((user) => action.payload.UserId !== user.UserId) 
			},
			HasChanged: true
		};
	}	
	case 'ON_ADD_LTA_PAIR_FULFILLED': {
		if (!action.payload) {
			return;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				LTAPairs: [...state.ChangedUser.LTAPairs, {...action.payload}]
			},
			HasChanged: true
		};
	}	
	case 'ON_REMOVE_CIM_PAIR_FULFILLED': {
		if (!action.payload || !action.payload.UserId) {
			return;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				CIMPairs: state.ChangedUser.CIMPairs.filter((user) => action.payload.UserId !== user.UserId) 
			},
			HasChanged: true
		};
	}	
	case 'ON_ADD_CIM_PAIR_FULFILLED': {
		if (!action.payload) {
			return;
		}
		return {
			...state,
			ChangedUser: {
				...state.ChangedUser,
				CIMPairs: [...state.ChangedUser.CIMPairs, {...action.payload}]
			},
			HasChanged: true
		};
	}
	case 'ON_DELETE_LTA_USER_FULFILLED': {
		if (!action.payload || !action.payload.UserId || !(action.payload.UserId === state.OriginalUser.UserId)) {
			return state;
		}
		return {...state,
			OriginalUser: {...state.OriginalUser, isLTA: false, CIMPairs: []},
			ChangedUser: {...state.ChangedUser, isLTA: false, CIMPairs: []},
			HasChanged: false
		};
	}
	case 'ON_DELETE_CIM_USER_FULFILLED': {
		if (!action.payload || !action.payload.UserId || !(action.payload.UserId === state.OriginalUser.UserId)) {
			return state;
		}
		return {...state,
			OriginalUser: {...state.OriginalUser, isCIM: false, LTAPairs: []},
			ChangedUser: {...state.ChangedUser, isCIM: false, LTAPairs: []},
			HasChanged: false
		};
	}
	case 'ON_ADD_LTA_USER_FULFILLED': {
		if (!action.payload || !action.payload.UserId || !(action.payload.UserId === state.OriginalUser.UserId)) {
			return state;
		}
		return {...state,
			OriginalUser: {...state.OriginalUser, isLTA: true},
			ChangedUser: {...state.ChangedUser, isLTA: true},
			HasChanged: false
		};
	}
	case 'ON_ADD_CIM_USER_FULFILLED': {
		if (!action.payload || !action.payload.UserId || !(action.payload.UserId === state.OriginalUser.UserId)) {
			return state;
		}
		return {...state,
			OriginalUser: {...state.OriginalUser, isCIM: true},
			ChangedUser: {...state.ChangedUser, isCIM: true},
			HasChanged: false
		};
	}
	case 'ON_DELETE_USER_FULFILLED': {
		return {
			MainSearch: [],
			SecondarySearch: [],
			OriginalUser: {},
			ChangedUser: {},
			RelayServers: [],
			OriginalAvatar: DEFAULT_AVATAR,
			ChangedAvatar: DEFAULT_AVATAR,
			UserError: '',
			HasChanged: false
		};	
	}
	case 'ON_SET_PASSWORD_FULFILLED': {
		return ({
			...state,
			ChangedUser: {...state.ChangedUser, Password: '', MatchPassword: ''},
			UserError: '',
			HasChanged: false
		});
	}
	case 'ON_SET_PASSWORD_REJECTED': {
		if (!action || !action.error) {
			return state;
		}
		return ({
			...state, 
			UserError: action.error
		});
	}
	case 'ON_SET_USER_SHORT_DESCR_PENDING': { // Changed from Fulfilled to Pending
		if (!action.payload || !action.payload.UserId ||
			!action.payload.UserId === state.OriginalUser.UserId) {
			return state;
		}
		return ({
			...state,
			OriginalUser: {
				...state.OriginalUser,
				...action.payload
			},
			HasChanged: false,
			UserError: ''
		});
	}
	case 'ON_SET_USER_SHORT_DESCR_REJECTED': {
		if (!action.error) {
			return state;
		}
		return {
			...state,
			UserError: action.error
		};
	}
	case 'ON_DELETE_USER_PAIRING_FULFILLED': {
		return ({
			...state,
			HasChanged: false
		});
	}
	case 'ON_ADD_TEMPORARY_RELAY_SERVER_FULFILLED': {
		if (!action.payload || !action.payload.length) {
			return state;
		}
		if (state.ChangedRelayServers.includes(action.payload)) {
			return state;
		}
		return ({
			...state,
			ChangedRelayServers: [...state.ChangedRelayServers, action.payload],
			HasChanged: true
		});
	}
	case 'ON_REMOVE_TEMPORARY_RELAY_SERVER_FULFILLED': {
		if (!action.payload || !action.payload.length) {
			return state;
		}
		return ({
			...state,
			ChangedRelayServers: state.ChangedRelayServers.filter((server) => {return server !== action.payload;}),
			HasChanged: true
		});
	}
	case 'ON_ADD_RELAY_PAIRING_FULFILLED': {
		if (!action.payload || !action.payload.UserId || !action.payload.UserId === state.OriginalUser.UserId || !action.payload.RelayName)  {
			return state;
		}
		return ({
			...state,
			OriginalRelayServers: [...state.OriginalRelayServers, action.payload.RelayName],
			ChangedRelayServers: [...state.ChangedRelayServers.filter((server) => {return server !== action.payload.RelayName;}), action.payload.RelayName],
			HasChanged: false
		});
	}
	case 'ON_REMOVE_RELAY_PAIRING_FULFILLED': {
		if (!action.payload || !action.payload.UserId || !action.payload.UserId === state.OriginalUser.UserId || !action.payload.RelayName) {
			return state;
		}
		return ({
			...state,
			OriginalRelayServers: state.OriginalRelayServers.filter((server) => {return server !== action.payload.RelayName;}),
			ChangedRelayServers: state.ChangedRelayServers.filter((server) => {return server !== action.payload.RelayName;}),
			HasChanged: false
		});
	}
	case 'ON_SET_SELECTED_FIRSTNAME_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return ({
			...state,
			ChangedUser: {...state.ChangedUser, FirstName: action.payload.FirstName},
			HasChanged: true
		});
	}
	case 'ON_SET_SELECTED_LASTNAME_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return ({
			...state,
			ChangedUser: {...state.ChangedUser, LastName: action.payload.LastName},
			HasChanged: true
		});
	}
	case 'ON_SIGNUP_REJECTED': {
		if (!action.error && !action.payload.Error) {
			return state;
		}
		if (action.payload && action.payload.Error) {
			if (action.payload.Error.includes('User with this name Already Exists')) {
				return ({
					...state,
					UserError: 'That Username is already taken!'
				});
			}
			if (action.payload.Error.includes('User Name Must be at least 5 characters')) {
				return ({
					...state,
					UserError: 'Username Must be at least 5 Characters'
				});
			}
			return ({
				...state,
				UserError: 'Failed to Process Signup'
			});
		}
		return ({
			...state,
			UserError: action.error || action.payload.Error
		});
	}
	case 'ON_SEND_INVITATION_REJECTED': {
		if (!action.payload.Error) {
			return state;
		}
		return ({
			...state,
			UserError: 'Failed to Send Signup Invitation, Ensure your Email is Valid'
		});
	}
	case 'ON_SIGNUP_FULFILLED': {
		return {
			...state,
			UserMessage: 'Check Your Email for a Confirmation Link'
		};
	}
	case 'ON_SEND_INVITATION_FULFILLED': {
		return ({
			...state,
			UserError: '',
			HasChanged: false
		});
	}
	case 'ON_GET_ADDRESS_BOOK_FULFILLED': {
		if (!action.payload || !action.payload.UserShortDescrs || !action.payload.GroupId) {
			return state;
		}
		const ContactsDirectoryObject = {};
		action.payload.UserShortDescrs.forEach(user => {
			ContactsDirectoryObject[user.UserId] = {...user, Avatar: DEFAULT_AVATAR};
		});
		return ({
			...state,
			ContactsDirectory: ContactsDirectoryObject,
			ContactsGroupId: action.payload.GroupId
		});
	}
	case 'ON_GET_STATUS_FULFILLED': {
		if (!action.payload || !action.payload.Status || !action.payload.Peer) {
			return state;
		}
		return ({
			...state,
			ContactsDirectory: {...state.ContactsDirectory, [action.payload.Peer]: {...state.ContactsDirectory[action.payload.Peer], UStatus: action.payload.Status}} 
		});
	}
	case 'ON_SEARCH_USERS_FULFILLED': {
		if (!action.payload || !action.payload.Lst) {
			return state;
		}
		const MainSearchObject = {};
		action.payload.Lst.forEach(user => {
			MainSearchObject[user.UserId] = {...user, Avatar: DEFAULT_AVATAR};
		});
		return ({
			...state,
			MainSearch: MainSearchObject
		});
	}
	case 'ON_INCOMING_CALL_PENDING': {
		if (!action.payload || !action.payload.UserId) {
			return state;
		}
		return ({
			...state,
			CallerID: action.payload.UserId,
			CallerAvatar: DEFAULT_AVATAR
		});
	}
	case 'ON_CALL_USER_ID_PENDING': {
		if (!action.payload || !action.payload.UserId) {
			return state;
		}
		return ({
			...state,
			CallerID: action.payload.UserId,
			CallerAvatar: DEFAULT_AVATAR
		});
	}
	default: 
		return state;
	}
}

export default usermanagement;