
export function attemptOpenModal(id) {
	return {
		type: 'ATTEMPT_MODAL_OPEN',
		payload: id
	};
}

export function attemptCloseModal(id) {
	return {
		type: 'ATTEMPT_MODAL_CLOSE',
		payload: id
	};
}

export function updateCurrentTime() {
	return {
		type: 'UPDATE_CURRENT_TIME',
		payload: Date.now()
	};
}

export function setCurrentInformationPanel(panelName) {
	return {
		type: 'UPDATE_CURRENT_INFORMATION_PANEL',
		payload: panelName
	};
}

export function toggleFullscreenRemote() {
	return {
		type: 'TOGGLE_FULLSCREEN_REMOTE'
	};
}

export function setShowQueueOverlay(value) {
	return {
		type: 'SET_SHOW_QUEUE_OVERLAY',
		payload: value
	};
}

export function toggleFixedLayout() {
	return {
		type: 'TOGGLE_FIXED_LAYOUT'
	};
}

export function toggleHideLeftPanel() {
	return {
		type: 'TOGGLE_HIDE_LEFT_PANEL'
	};
}

export function setHideRightOverlays(value) {
	return {
		type: 'SET_HIDE_RIGHT_OVERLAYS',
		payload: value
	};
}

export function setOutOfShowImage(value) {
	return {
		type: 'SET_OUT_OF_SHOW_IMAGE',
		payload: value
	};
}

export function setScreeningShowImage(value) {
	return {
		type: 'SET_SCREENING_SHOW_IMAGE',
		payload: value
	};
}

export function setStudioShowImage(value) {
	return {
		type: 'SET_STUDIO_SHOW_IMAGE',
		payload: value
	};
}

export function setCompletedShowImage(value) {
	return {
		type: 'SET_COMPLETED_SHOW_IMAGE',
		payload: value
	};
}

export function setHeaderLogoImage(value) {
	return {
		type: 'SET_HEADER_LOGO_IMAGE',
		payload: value
	};
}

export function setLocalVideoPosition(IsTop, IsLeft)
{
	return {
		type: 'SET_LOCAL_VIDEO_POSITION',
		payload: {
			IsTop,
			IsLeft
		}
	};
}

export function toggleHideLocalVideo() {
	return {
		type: 'TOGGLE_HIDE_LOCAL_VIDEO'
	};
}

export function setEnableAccountFunctionality(value) {
	return {
		type: 'SET_ENABLE_ACCOUNT_FUNCTIONALITY',
		payload: value
	};
}

export function setCustomShowField(FieldNumber, IsVisible, Label) {
	return (dispatch) => {
		if (FieldNumber < 1 || FieldNumber > 5)
		{
			dispatch({
				type: 'SET_CUSTOM_SHOW_FIELD_REJECTED',
				payload: { Error: 'Invalid Field Number to Set Custom Field'}
			});
			return;
		}
		dispatch({
			type: 'SET_CUSTOM_SHOW_FIELD',
			payload: {
				FieldNumber,
				IsVisible,
				Label
			}
		});
	};
}