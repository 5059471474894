import React from 'react';
import { connect } from 'react-redux';
import AvatarHandler from './AvatarHandler';
import {
	setSelectedPassword,
	setSelectedMatchPassword,
	setSelectedDispName,
	setSelectedEmail,
	setSelectedCompanyName,
	setUserShortDesc,
	setAvatar,
	setPassword
} from '../../../../Redux/Actions/changedUserActions';

import './EditProfilePanel.css';

import genericStyle from '../PanelGeneric.module.css';
import TextField from '../../TextField';

function mapStateToProps(store) {
	return {
		ChangedPassword: store.usermanagement.ChangedUser.Password || '',
		ChangedMatchPassword: store.usermanagement.ChangedUser.MatchPassword || '',
		ChangedEmail: store.usermanagement.ChangedUser.Email || '',
		ChangedCompany: store.usermanagement.ChangedUser.CompanyName || '',
		ChangedDispName: store.usermanagement.ChangedUser.DispName || '',
		ChangedUserId: store.usermanagement.ChangedUser.UserId || 0,
		ChangedLocation: store.usermanagement.ChangedUser.Location || '',
		ChangedAvatar: store.usermanagement.ChangedAvatar || '',
		ChangedUserName: (((store.usermanagement.ChangedUser.Channels || [])[0] || {}).UserName || ''),
		OriginalEmail: store.usermanagement.OriginalUser.Email || '',
		OriginalCompany: store.usermanagement.OriginalUser.CompanyName || '',
		OriginalDispName: store.usermanagement.OriginalUser.DispName || '',
		OriginalLocation: store.usermanagement.OriginalUser.Location || '',
		OriginalAvatar: store.usermanagement.OriginalAvatar || '',
		UserError: store.usermanagement.UserError || ''
	};
}

class EditProfilePanel extends React.Component {
	constructor(props) {
		super(props);

		this.changeField = this.changeField.bind(this);
		this.onSaveChanges = this.onSaveChanges.bind(this);
	}

	changeField(action) {
		return (event) => {
			if (!this.props.ChangedUserId) {
				return;
			}
			if (!event || !event.target) {
				return;
			}
			this.props.dispatch(action(event.target.value));
		};
	}

	onSaveChanges() {
		if (!this.props.ChangedUserId) {
			return;
		}
		if (this.props.ChangedAvatar !== this.props.OriginalAvatar) {
			this.props.dispatch(setAvatar(this.props.ChangedAvatar, this.props.ChangedUserId));
		}
		if (this.props.ChangedPassword || this.props.ChangedMatchPassword) {
			this.props.dispatch(setPassword(this.props.ChangedUserName, this.props.ChangedUserId, this.props.ChangedPassword, this.props.ChangedMatchPassword));
		}
		if (
			this.props.ChangedEmail !== this.props.OriginalEmail ||
			this.props.ChangedCompany !== this.props.OriginalCompany ||
			this.props.ChangedDispName !== this.props.OriginalDispName ||
			this.props.ChangedLocation !== this.props.OriginalLocation
		) {
			this.props.dispatch(setUserShortDesc(this.props.ChangedUserId, this.props.ChangedDispName, this.props.ChangedCompany, this.props.ChangedEmail, this.props.ChangedLocation));
		}
	}

	render() {
		return (
			<div className={genericStyle.PanelWrapper}>
				<div className={genericStyle.BackgroundWrapper}>
					<div className={genericStyle.Header}>Edit Profile</div>
					{(this.props.UserError ? <div className={genericStyle.Error}>{this.props.UserError}</div> : null)}
				</div>
				<AvatarHandler/>
				<form className={genericStyle.SurfaceWrapper}>
					<TextField label='Display Name' type='text' onChange={this.changeField(setSelectedDispName)} value={this.props.ChangedDispName} placeholder={'Enter Your Display Name...'} autoComplete={'name'}/>
					<TextField label='Email' type='text' onChange={this.changeField(setSelectedEmail)} value={this.props.ChangedEmail} placeholder={'Enter Your Email...'} autoComplete={'email'}/>
					<TextField label='Company' type='text' onChange={this.changeField(setSelectedCompanyName)} value={this.props.ChangedCompany} placeholder={'Enter Your Company Name...'} autoComplete={'organization'}/>
					<TextField label='Password' type='password' onChange={this.changeField(setSelectedPassword)} value={this.props.ChangedPassword} placeholder={'Enter Your Password...'} autoComplete={'new-password'}/>
					<TextField label='Confirm Password' type='password' onChange={this.changeField(setSelectedMatchPassword)} value={this.props.ChangedMatchPassword} placeholder={'Enter Your Password Again...'} autoComplete={'off'}/>
				</form>
				<div className={genericStyle.Footer}>
					<div className={'Button Primary'} onClick={this.onSaveChanges}>Save</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(EditProfilePanel);