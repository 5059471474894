import Logger from '../../Logging';
import GnuralRTC from '../GnuralRTC';
import ReduxConnector from '../ReduxConnector';

const INFO = 'Utilities/Gnural/WebRTCStats';

class WebRTCStats {
	constructor() {
		this.props = { remoteMediaStreamID: '', remotePeerID: '' };

		this.updateProperties = this.updateProperties.bind(this);
		this.startFetchingStats = this.startFetchingStats.bind(this);
		this.timeout = null;
		this.InstanceCount = 0;
	}

	async updateProperties(newProperties) {
		if (newProperties.remoteMediaStreamID === this.props.remoteMediaStreamID
			&& newProperties.remotePeerID === this.props.remotePeerID) {
			return;
		}
		this.props.remoteMediaStreamID = newProperties.remoteMediaStreamID;
		this.props.remotePeerID = newProperties.remotePeerID || '';
		if (!this.props.remoteMediaStreamID || !this.props.remotePeerID) {
			if (this.timeout) {
				clearTimeout(this.timeout);
			}
		}
		this.InstanceCount++;
		await this.startFetchingStats(this.InstanceCount);
	}

	async startFetchingStats(currentInstance) {
		if (currentInstance != this.InstanceCount) {
			return;
		}
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		if (!this.props.remoteMediaStreamID || !this.props.remotePeerID) {
			return;
		}
		if (!GnuralRTC.Instance.PeerConnections || !GnuralRTC.Instance.PeerConnections[this.props.remotePeerID]
			|| !GnuralRTC.Instance.PeerConnections[this.props.remotePeerID].Connection) {
			return;
		}
		let statsGoogle = {};
		try {
			statsGoogle = await new Promise(async (resolve, reject)=> { /* eslint-disable-line no-async-promise-executor */
				try {
					await GnuralRTC.Instance.PeerConnections[this.props.remotePeerID].Connection.getStats((report) => {
						if (!report) {
							reject();
						}
						const stats = report.result();
						const finalStats = {};
						stats.forEach(stat => {
							finalStats[stat.id] = {};
							stat.names().forEach(name => {
								finalStats[stat.id][name] = stat.stat(name);
							});
						});
						resolve(finalStats);
					});
				} catch (e) {
					reject();
				}
			});
		} catch (e) {
			Logger.error(INFO, 'Failed to Fetch Google Stats');
		}
		const statsSpecResults = await GnuralRTC.Instance.PeerConnections[this.props.remotePeerID].Connection.getStats();
		const statsSpec = {};
		statsSpecResults.forEach(stat => {
			if (!stat.id) {
				return;
			}
			statsSpec[stat.id] = stat;
		});
		const finalStats = {...statsGoogle, ...statsSpec};
		ReduxConnector.dispatch({
			type: 'ON_WEBRTC_STATS',
			payload: finalStats
		});
		if (!this.timeout) {
			setTimeout(() => {this.startFetchingStats(currentInstance);}, 4000);
		}
	}
}

function neededStoreVariables(store) {
	return store.media;
}

const WebRTCStatsInstance = new WebRTCStats();

ReduxConnector.connect(neededStoreVariables, WebRTCStatsInstance.updateProperties);

export default WebRTCStatsInstance;