import { combineReducers } from 'redux';
import user from './user';
import websocket from './websocket';
import userinterface from './userinterface';
import media from './media';
import stats from './stats';
import call from './call';
import callin from './callin';
import chat from './chat';
import sessionInformation from './sessionInformation';
import usermanagement from './usermanagement';
import polling from './polling';
import configurator from './configurator';

const combinedReducer = combineReducers({ user, websocket, userinterface, media, stats, call, sessionInformation, callin, chat, usermanagement, polling, configurator});

export default combinedReducer;
