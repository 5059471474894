const defaultState = {
	isChatWindowMinimized: true,
	isChatWindowActive: false,
	SelectedChatObject: null,
	MessageToSend: '',
	ChatObjects: {},
	MessageCount: 0
};

function closeChats(state, UserId) {
	let shouldBeVisible = false;
	let newSelectedChatObject = (
		state.SelectedChatObject === UserId ?
			null :
			state.SelectedChatObject
	);
	let newMessageToSend = (
		state.SelectedChatObject === UserId ? 
			'' :
			state.MessageToSend
	);
	Object.values(state.ChatObjects).forEach(ChatObject => {
		console.log(ChatObject);
		if (ChatObject.UserId !== UserId) {
			shouldBeVisible |= ChatObject.ShouldDisplay;
			if (!newSelectedChatObject && ChatObject.ShouldDisplay) {
				newSelectedChatObject = ChatObject.UserId;
			}
		}
	});
	return {
		...state,
		MessageToSend: newMessageToSend,
		isChatWindowActive: shouldBeVisible,
		SelectedChatObject: newSelectedChatObject,
		ChatObjects: {
			...state.ChatObjects,
			[UserId]: {
				...state.ChatObjects[UserId],
				ShouldDisplay: false,
				hasNewMessages: false
			}
		}
	};
}

function chat(state={...defaultState}, action) {
	switch(action.type) {
	case 'OPEN_CHAT': {
		if (!action.payload || !action.payload.UserId || !action.payload.DispName || action.payload.UserId === state.SelectedChatObject) {
			return state;
		}
		if (state.ChatObjects[action.payload.UserId]) {
			return {
				...state,
				MessageToSend: '',
				isChatWindowActive: true,
				isChatWindowMinimized: false,
				SelectedChatObject: action.payload.UserId,
				ChatObjects: {
					...state.ChatObjects,
					[action.payload.UserId]: {
						...state.ChatObjects[action.payload.UserId],
						ShouldDisplay: true,
						hasNewMessages: false
					}
				}
			};
		}
		return {
			...state,
			MessageToSend: '',
			isChatWindowActive: true,
			isChatWindowMinimized: false,
			SelectedChatObject: action.payload.UserId,
			ChatObjects: {
				...state.ChatObjects,
				[action.payload.UserId]: {
					ShouldDisplay: true,
					DispName: action.payload.DispName,
					UserId: action.payload.UserId,
					Messages: [],
					hasNewMessages: false
				}
			}
		};
	}
	case 'ON_GET_STATUS_FULFILLED': {
		if (!action.payload || !action.payload.Peer || action.payload.Status !== 'OffLine') {
			return state;
		}
		return closeChats(state, action.payload.Peer);
	}
	case 'CLOSE_CHAT': {
		if (!action.payload || !action.payload.UserId) {
			return state;
		}
		if (!state.ChatObjects[action.payload.UserId]) {
			return state;
		}
		return closeChats(state, action.payload.UserId);
	}
	case 'UPDATE_CHAT_MESSAGE': {
		if (!action.payload) {
			return state;
		}
		return {
			...state,
			MessageToSend: action.payload.Message || ''
		};
	}
	case 'ON_TEXT_TO_PEER_SENT': {
		if (!action.payload ||
			!action.payload.RecipientUserId ||
			!action.payload.SenderDisplayName ||
			!state.ChatObjects[action.payload.RecipientUserId]) {
			return state;
		}

		return {
			...state,
			MessageToSend: '',
			ChatObjects: {
				...state.ChatObjects,
				[action.payload.RecipientUserId]: {
					...state.ChatObjects[action.payload.RecipientUserId],
					Messages: [...state.ChatObjects[action.payload.RecipientUserId].Messages, {...action.payload, LocalCount: state.MessageCount++}]
				}
			}
		};
	}
	case 'ON_TEXT_TO_PEER_RECEIVED': {
		if (!action.payload ||
			!action.payload.SenderDisplayName ||
			!action.payload.SenderUserId) {
			return state;
		}
		if (!state.ChatObjects[action.payload.SenderUserId]) {
			return {
				...state,
				isChatWindowActive: true,
				isChatWindowMinimized: false,
				SelectedChatObject: state.SelectedChatObject || action.payload.SenderUserId,
				ChatObjects: {
					...state.ChatObjects,
					[action.payload.SenderUserId]: {
						ShouldDisplay: true,
						DispName: action.payload.SenderDisplayName,
						UserId: action.payload.SenderUserId,
						Messages: [{...action.payload, LocalCount: state.MessageCount++}],
						hasNewMessages: ((state.SelectedChatObject || action.payload.SenderUserId) === action.payload.SenderUserId ? false : true)
					}
				}
			};
		}
		return {
			...state,
			isChatWindowActive: true,
			SelectedChatObject: state.SelectedChatObject || action.payload.SenderUserId,
			ChatObjects: {
				...state.ChatObjects,
				[action.payload.SenderUserId]: {
					...state.ChatObjects[action.payload.SenderUserId],
					ShouldDisplay: true,
					Messages: [...state.ChatObjects[action.payload.SenderUserId].Messages, {...action.payload, LocalCount: state.MessageCount++}],
					hasNewMessages: (state.isChatWindowMinimized ? true : ((state.SelectedChatObject || action.payload.SenderUserId) === action.payload.SenderUserId ? false : true))
				}
			}
		};
	}
	case 'TOGGLE_MINIMIZE_CHAT_WINDOW': {
		return {
			...state,
			isChatWindowMinimized: !state.isChatWindowMinimized,
			ChatObjects: {
				...state.ChatObjects,
				[state.SelectedChatObject]: {
					...state.ChatObjects[state.SelectedChatObject],
					hasNewMessages: false
				}
			}
		};
	}
	case 'LOGIN_ENDED': {
		return {
			...defaultState
		};
	}
	default: {
		return state;
	}
	}
}

export default chat;