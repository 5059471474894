import React from 'react';
import { connect } from 'react-redux';
import { setLocalVideoPosition } from '../../../Redux/Actions/userinterfaceActions';
import LocalMediaHandler from '../../../Utilities/Gnural/LocalMediaHandler';
import CustomVideo from '../../CustomVideo';
import style from './LocalVideoPanel.module.css';

function mapStateToProps(store) {
	return ({
		localMediaStreamID: store.media.localMediaStreamID,
		isProcessing: store.media.isProcessing,
		localVideoIsTop: store.userinterface.localVideoIsTop,
		localVideoIsLeft: store.userinterface.localVideoIsLeft,
		hideRightOverlays: store.userinterface.hideRightOverlays,
		fixedLayout: store.userinterface.fixedLayout,
		hideLeftPanel: store.userinterface.hideLeftPanel && store.callin.inShow,
		hideLocalVideo: store.userinterface.hideLocalVideo
	});
}

class LocalVideoPanel extends React.Component {

	constructor(props) {
		super(props);

		this.onUpdateQuadrant = this.onUpdateQuadrant.bind(this);

		this.state = {
			localStream: undefined
		};
	}
	
	componentDidMount() {
		this.setState({
			localStream: LocalMediaHandler.getLocalMediaStream()
		});
	}

	componentDidUpdate(nextProps) {
		if (nextProps.localMediaStreamID !== this.props.localMediaStreamID) {
			this.setState({
				localStream: LocalMediaHandler.getLocalMediaStream()
			});
		}
	}

	onUpdateQuadrant(IsTop, IsLeft) {
		return () => {
			this.props.dispatch(setLocalVideoPosition(IsTop, IsLeft));
		};
	}

	render() {
		return (
			<div className={[
				style.LocalVideoWrapper,
				(!this.props.hideLeftPanel || this.props.hideLocalVideo ? style.Hidden : null),
				(this.props.localVideoIsTop ? style.Top : style.Bottom),
				(this.props.localVideoIsLeft ? style.Left : style.Right),
				(this.props.fixedLayout ? style.Offset: this.props.hideRightOverlays ? '' : style.Offset)
			].join(' ')}>
				<CustomVideo
					className={[style.LocalVideo].join(' ')}
					srcObject={this.state.localStream}
					muted={true}
					shouldContainFraming={true}
					VideoFramingStyle={style.VideoFraming}
				/>
				{this.props.isProcessing ? 
					<div className={style.IsProcessingWrapper}>
						<div className={style.IsProcessingGraphic}>
							<div className={style.IsProcessingGraphicInner}>Loading</div>
						</div>
					</div>
					:
					null
				}
				<div className={style.VideoQuadrantAdjusterWrapper}>
					<div onClick={this.onUpdateQuadrant(true, true)}/>
					<div onClick={this.onUpdateQuadrant(true, false)}/>
					<div onClick={this.onUpdateQuadrant(false, true)}/>
					<div onClick={this.onUpdateQuadrant(false, false)}/>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(LocalVideoPanel);