import React from 'react';
import { connect } from 'react-redux';
import ConvertIntToLetter from '../../Utilities/LetterConversion';
import style from './Polling.module.css';

function mapStateToProps() {
	return ({
	});
}

class QuestionField extends React.Component {
	render() {
		return (
			<div 
				className={[style.PollingField, (this.props.IsSelected ? style.Active : '')].join(' ')} 
				onClick={this.props.onSelectResponse}>
				<div>{(this.props.IsNumbered ? 
					this.props.FieldOrder + 1 :
					ConvertIntToLetter(this.props.FieldOrder)) + ')'}
				</div>
				<div>{this.props.Field}</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(QuestionField);