import React from 'react';
import { connect } from 'react-redux';
import RemoteVideoPanel from './RemoteVideoPanel';
import ChatWindow from './ChatWindow';
import { getContextFromURL } from '../../Utilities/Gnural/SessionInformation';
import { setContext } from '../../Redux/Actions/callActions';
import '../../Utilities/Gnural/LocalMediaHandler';
import style from './Main.module.css';
import ToggleChatWindowButton from './ToggleChatWindowButton';
import MediaFooterPanel from './MediaFooterPanel';
import LeftPanel from './LeftPanel';
import { setHideRightOverlays, toggleHideLeftPanel } from '../../Redux/Actions/userinterfaceActions';
import LocalVideoPanel from './LocalVideoPanel';
import Polling from '../Polling';

import SettingsIcon from '../../VectorComponents/SettingsComponent';

import footerButtonStyle from './MediaFooterPanel/MediaFooterPanel.module.css';

function mapStateToProps(store) {
	return {
		currentInformationPanel: store.userinterface.currentInformationPanel,
		isMobileDevice: store.sessionInformation.isMobileDevice,
		UserId: store.user.UserId,
		CallState: store.call.CallState,
		CallinState: store.callin.CallState,
		inShow: store.callin.inShow,
		isSafari: store.sessionInformation.isSafari,
		isChatWindowActive: false, //store.chat.isChatWindowActive,
		isInteractionEnabled: store.polling.InteractionState ? true : false,
		fullscreenRemote: store.userinterface.fullscreenRemote,
		DispName: store.user.DispName,
		QuestionId: store.polling.QuestionId,
		fixedLayout: store.userinterface.fixedLayout,
		hideLeftPanel: store.userinterface.hideLeftPanel,
		hideRightOverlays: store.userinterface.hideRightOverlays,
		headerLogoImage: store.userinterface.headerLogoImage,
		hidePollingPanel: store.polling.hidePollingPanel,
		localVideoIsLeft: store.userinterface.localVideoIsLeft
	};
}

class Main extends React.Component {
	constructor(props) {
		super(props);

		this.MainRef = React.createRef();

		this.onHandleMouseMovement = this.onHandleMouseMovement.bind(this);
		this.onToggleHideLeftPanel = this.onToggleHideLeftPanel.bind(this);
		this.currentTimeout = null;
		this.getProperShowBorderStyle = this.getProperShowBorderStyle.bind(this);
	}

	componentDidMount() {
		if (this.props.isSafari) {
			//var ignore = import('./SafariOverride.css');
		}
		
		this.MainRef.current.addEventListener('mousemove', this.onHandleMouseMovement);
		let context = getContextFromURL();

		if (context) {
			this.props.dispatch(setContext(context));
		}
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.CallinState !== prevProps.CallinState ||
			this.props.CallState !== prevProps.CallState)
		{
			this.onHandleMouseMovement();
		}
		return;
	}

	componentWillUnmount() {
		if (this.currentTimeout) { clearTimeout(this.currentTimeout); }
		this.MainRef.current.removeEventListener('mousemove', this.onHandleMouseMovement);
	}

	onHandleMouseMovement() {
		if (this.props.hideRightOverlays)
		{
			this.props.dispatch(setHideRightOverlays(false));
		}
		if (this.currentTimeout) {
			clearTimeout(this.currentTimeout);
		}
		this.currentTimeout = setTimeout(() => {
			this.props.dispatch(setHideRightOverlays(true));
			clearTimeout(this.currentTimeout);}
		, 3000);
	}
	
	onToggleHideLeftPanel() {
		this.props.dispatch(toggleHideLeftPanel());
	}

	getProperShowBorderStyle() {
		if (this.props.fullscreenRemote)
		{
			return '';
		}
		switch (this.props.CallinState)
		{
		case 'Incoming':
		case 'Studio':
		case 'Finished':
		case 'Show':
		case 'Blocked':
		{
			return style.Standby;
		}
		case 'Screening':
		{
			if (this.props.CallState === 'ACTIVE')
			{
				return style.Screening;
			}
			return style.Standby;
		}
		case 'OffAir':
		{
			return style.OffAir;
		}
		case 'OnAir':
		{
			return style.OnAir;
		}
		default: return '';
		}
	}

	setProperDisplay() {
		const surfaceColorText = 'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--Surface-Color-Text') + ')';
		return (
			<div className={style.Main} ref={this.MainRef}>
				{!this.props.fullscreenRemote ? <LeftPanel/> : null}
				<div className={[ style.RightPanelWrapper, (this.props.fixedLayout ? '' : this.getProperShowBorderStyle()) ].join(' ')}>
					{ !this.props.fullscreenRemote ? <div className={[style.RightPanelHeader, (this.props.fixedLayout ? style.Fixed : this.props.hideRightOverlays ? style.Hidden : null)].join(' ')}>
						{this.props.hideLeftPanel ?
							<SettingsIcon Color={surfaceColorText} className={[footerButtonStyle.InteractionButton, footerButtonStyle.NoBorder].join(' ')} onClick={this.onToggleHideLeftPanel}/>
							:
							<div/>	
						}
						{this.props.DispName ? <div className={[style.SurfaceText, style.AccountName].join(' ')}>{this.props.DispName}</div> : null}
						{this.props.headerLogoImage ?
							<div className={style.HeaderLogoWrapper}>
								<img className={style.HeaderLogo} src={this.props.headerLogoImage}/>	
							</div>
							:
							null
						}
					</div> : null}
					<div className={[ style.RightPanelBody, (this.props.fixedLayout ? this.getProperShowBorderStyle() : '') ].join(' ')}>
						<RemoteVideoPanel/>
						{!this.props.fullscreenRemote ? <ChatWindow/> : null}
					</div>
					{!this.props.fullscreenRemote ? <div className={[style.RightPanelFooter, (this.props.fixedLayout ? style.Fixed : this.props.hideRightOverlays ? style.Hidden : null)].join(' ')}>
						<MediaFooterPanel/>
						{(this.props.isChatWindowActive ? <ToggleChatWindowButton/> : null)}
					</div> : null}
					{ !this.props.fullscreenRemote ? <LocalVideoPanel/> : null }
					{ !this.props.fullscreenRemote && !this.props.hidePollingPanel && this.props.hideLeftPanel && this.props.QuestionId ? 
						<div className={[
							style.PollingWrapper,
							(this.props.localVideoIsLeft ? style.Right: style.Left),
							(this.props.fixedLayout || !this.props.hideRightOverlays ? style.Offset : null)
						].join(' ')}>
							<Polling/>
						</div> : null
					}
				</div>
			</div>
		);
	}

	render() {
		return this.setProperDisplay(); 
	}
}

export default connect(mapStateToProps)(Main);

