import React from 'react';
import { connect } from 'react-redux';
import { setJoinShowField, enterShow } from '../../../../Redux/Actions/showActions';
import TextField from '../../TextField';
import style from './JoinShowPanel.module.css';
import genericStyle from '../PanelGeneric.module.css';
import fieldStyle from '../../TextField/TextField.module.css';

function mapStateToProps(store) {
	return {
		CookieGuid: store.callin.CookieGuid,
		ShowName: store.callin.ShowName,
		Name: store.callin.Name,
		Email: store.callin.Email,
		Phone: store.callin.Phone,
		Twitter: store.callin.Twitter,
		Facebook: store.callin.Facebook,
		GeoLocation: store.callin.GeoLocation,
		Notes: store.callin.Notes,
		ShowError: store.callin.ShowError,
		RestrictedFields: store.callin.RestrictedFields,
		CustomShowField1IsVisible: store.userinterface.CustomShowField1IsVisible,
		CustomShowField1Label: store.userinterface.CustomShowField1Label,
		CustomShowField2IsVisible: store.userinterface.CustomShowField2IsVisible,
		CustomShowField2Label: store.userinterface.CustomShowField2Label,
		CustomShowField3IsVisible: store.userinterface.CustomShowField3IsVisible,
		CustomShowField3Label: store.userinterface.CustomShowField3Label,
		CustomShowField4IsVisible: store.userinterface.CustomShowField4IsVisible,
		CustomShowField4Label: store.userinterface.CustomShowField4Label,
		CustomShowField5IsVisible: store.userinterface.CustomShowField5IsVisible,
		CustomShowField5Label: store.userinterface.CustomShowField5Label,
	};
}

class JoinShowPanel extends React.Component {
	constructor(props) {
		super(props);

		this.onSetJoinShowField = this.onSetJoinShowField.bind(this);
		this.onEnterShow = this.onEnterShow.bind(this);
		this.onHandleScroll = this.onHandleScroll.bind(this);
		
		this.InnerWrapperRef = React.createRef();
		
		this.state = {
			displayScrollIndicatorBottom: false,
			displayScrollIndicatorTop: true 
		};
	}

	componentDidMount() {
		this.InnerWrapperRef.current.addEventListener('scroll', this.onHandleScroll);
		window.addEventListener('resize', this.onHandleScroll);
		this.onHandleScroll();
	}

	componentDidUpdate() {
		this.onHandleScroll();
	}

	componentWillUnmount() {
		this.InnerWrapperRef.current.removeEventListener('scroll', this.onHandleScroll);
		window.removeEventListener('resize', this.onHandleScroll);
	}

	onHandleScroll() {
		let element = this.InnerWrapperRef.current;

		let newIndicatorTopState = (element.scrollTop != 0);
		let newIndicatorBottomState = (element.scrollHeight - element.scrollTop !== element.clientHeight);
		
		if (newIndicatorTopState !== this.state.displayScrollIndicatorTop ||
			newIndicatorBottomState != this.state.displayScrollIndicatorBottom)
		{
			this.setState({
				...this.state,
				displayScrollIndicatorBottom: newIndicatorBottomState,
				displayScrollIndicatorTop: newIndicatorTopState
			});
		}
	}

	onSetJoinShowField(event) {
		if (!event || !event.target) {
			return;
		}
		this.props.dispatch(setJoinShowField(event.target.name, event.target.value));
	}

	onEnterShow() {
		this.props.dispatch(enterShow({...this.props}));
	}

	render() {
		return (
			<div className={genericStyle.PanelWrapper} ref={this.InnerWrapperRef}>
				<div className={genericStyle.BackgroundWrapper}>
					<div className={genericStyle.Header}>Join a Show</div>
					{this.props.ShowError ? <div className={genericStyle.Error}>{this.props.ShowError}</div> : null}
				</div>
				<div className={genericStyle.SurfaceWrapper}>
					<TextField name={'ShowName'} WrapperStyle={fieldStyle.Big} onChange={this.onSetJoinShowField} label={'Show Name'} value={this.props.ShowName} placeholder='Enter the Show Name (Required)'/>
					<TextField onChange={this.onSetJoinShowField} name={'Name'} label={'Name'} value={this.props.Name} placeholder='Enter Your Name/Alias (Required)'/>
					{this.props.RestrictedFields ?
						null
						:
						<React.Fragment>
							{this.props.CustomShowField1IsVisible ? <TextField label={this.props.CustomShowField1Label} onChange={this.onSetJoinShowField} name={'Email'} value={this.props.Email} placeholder={'Enter Your ' + this.props.CustomShowField1Label + ' Here'}/> : null }
							{this.props.CustomShowField2IsVisible ? <TextField label={this.props.CustomShowField2Label} onChange={this.onSetJoinShowField} name={'Phone'} value={this.props.Phone} placeholder={'Enter Your ' + this.props.CustomShowField2Label + ' Here'}/> : null }
							{this.props.CustomShowField3IsVisible ? <TextField label={this.props.CustomShowField3Label} onChange={this.onSetJoinShowField} name={'Twitter'} value={this.props.Twitter} placeholder={'Enter Your ' + this.props.CustomShowField3Label + ' Here'}/> : null }
							{this.props.CustomShowField4IsVisible ? <TextField label={this.props.CustomShowField4Label} WrapperStyle={fieldStyle.Medium} onChange={this.onSetJoinShowField} name={'Facebook'} value={this.props.Facebook} placeholder={'Enter Your ' + this.props.CustomShowField4Label + ' Here'}/> : null }
							{this.props.CustomShowField5IsVisible ? <TextField label={this.props.CustomShowField5Label} WrapperStyle={fieldStyle.Medium} onChange={this.onSetJoinShowField} name={'GeoLocation'} value={this.props.GeoLocation} placeholder={'Enter Your ' + this.props.CustomShowField5Label + ' Here'}/> : null }
						</React.Fragment>
					}
					<TextField label={'Comments/Questions'} WrapperStyle={fieldStyle.Huge} onChange={this.onSetJoinShowField} name={'Notes'} value={this.props.Notes} placeholder='Enter Any Additional Questions/Comments'/>
				</div>
				<div className={'HorizontalRule'}></div>
				<div className={genericStyle.BackgroundWrapper}>
					<div className={style.JoinShowLegal}>By clicking "JOIN NOW" you Agree to have your Audio, Video, and any Submitted Information used in this Production</div>
				</div>
				<div className={'Spacer'}/>
				<div className={[genericStyle.Footer, (this.state.displayScrollIndicatorTop || this.state.displayScrollIndicatorBottom ? genericStyle.Scroll : '')].join(' ')} onClick={this.onEnterShow}>
					<div className={'Button Affirmative'}>Join Now</div>
				</div>
				<div className={[genericStyle.ScrollShadow, genericStyle.Top, (this.state.displayScrollIndicatorTop ? genericStyle.Active : '')].join(' ')}>▲</div>
				<div className={[genericStyle.ScrollShadow, genericStyle.Bottom, (this.state.displayScrollIndicatorBottom ? genericStyle.Active : '')].join(' ')}>▼</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(JoinShowPanel);